import axios, { AxiosResponse } from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import { Visitor, VisitorsQueryResponse } from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

// const API_URL = process.env.REACT_APP_THEME_API_URL

import { apiUrl } from '../../../../../apiurl'

const API_URL = apiUrl
const VISITOR_URL = `${API_URL}/visitor`
// const GET_VISITORS_URL = `${API_URL}/users/query`
const Share_Invitation = `${API_URL}/invitionhistories/getwhatsappinvitationdata`
const GET_VISITORS_URL = `${API_URL}/invitionhistories/getList`
const CHECK_MOB_AND_MAIL_URL = `${API_URL}/invitionhistories/checkmobileandmail`
const SEND_INVITE_URL = `${API_URL}/invitionhistories/saveInvition`
const EVENT_DATE_LISTING = `${API_URL}/eventdates`
const SAVE_INVITON = `${API_URL}/invitionhistories/StoreInvitation`
const getAllChaptersURL=`${API_URL}/chapters?filter[where][isdelete]=0`
const RESAVE_INVITON = `${API_URL}/invitionhistories/resendinvitationstore`

// export function addEvent(email: string) {
const sendInvite = (data: any): Promise<Event | undefined> => {

  //console.log("sendInvite 19 Called");
  return axios.post<any>(`${SEND_INVITE_URL}`, data).then((res: any) => res)
}

// export function addEvent(email: string) {
const checkMobileAndMail = (data: any): Promise<Event | undefined> => {

  //console.log("checkMobileAndMail 19 Called");
  return axios.post<any>(`${CHECK_MOB_AND_MAIL_URL}`, data).then((res: any) => res)
}

const getVisitors = (query: string): Promise<VisitorsQueryResponse> => {
  //console.log("getVisitors Called");
  return axios
    .get(`${GET_VISITORS_URL}?${query}`)
    // .get(`${GET_VISITORS_URL}`)
    .then((d: AxiosResponse<any>) => d.data).catch((e) => console.log(e))
}

const getVisitorById = (id: ID): Promise<Visitor | undefined> => {
  return axios
    .get(`${VISITOR_URL}/${id}`)
    // .then((response: AxiosResponse<Response<Visitor>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const createVisitor = (visitor: Visitor): Promise<Visitor | undefined> => {
  return axios
    .put(VISITOR_URL, visitor)
    // .then((response: AxiosResponse<Response<Visitor>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const updateVisitor = (visitor: Visitor): Promise<Visitor | undefined> => {
  return axios
    .post(`${VISITOR_URL}/${visitor.id}`, visitor)
    // .then((response: AxiosResponse<Response<Visitor>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const deleteVisitor = (visitorId: ID): Promise<void> => {
  return axios.delete(`${VISITOR_URL}/${visitorId}`).then(() => { })
}

const deleteSelectedVisitors = (visitorIds: Array<ID>): Promise<void> => {
  const requests = visitorIds.map((id) => axios.delete(`${VISITOR_URL}/${id}`))
  return axios.all(requests).then(() => { })
}
 function EventDateListAPI(chapterId:any, eventDate:any) {
  // &filter[where][event_date][gte]=${eventDate}
  return axios
    .get<any>(`${EVENT_DATE_LISTING}?filter[where][chapter_id]=${chapterId}&filter[where][event_date][gte]=${eventDate}&filter[order]=event_date&filter[limit]=4`)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}
const sendInvitationAPI = (visitorpanel: any): Promise<any | undefined> => {
  return axios
    .post(SAVE_INVITON, visitorpanel)
    // .then((response: AxiosResponse<Response<Visitorpanel>>) => response.data).catch((e) => console.log(e))
    .then((res: any) => {
      return res
    })
}

const reSendInvitationAPI = (visitorpanel: any): Promise<any | undefined> => {
  return axios
    .post(RESAVE_INVITON, visitorpanel)
    // .then((response: AxiosResponse<Response<Visitorpanel>>) => response.data).catch((e) => console.log(e))
    .then((res: any) => {
      return res
    })
}
const shareInvitationWhatappAPI = (eventid: any, invitee_name: any): Promise<any> => {
  return axios
    .post(Share_Invitation, {eventid, invitee_name})
    .then((res: AxiosResponse<Response<any>>) => res)
}
const getchapterList = (chapterId:any): Promise<any> => {
  return axios
    .get(`${getAllChaptersURL}`)
    .then((d: AxiosResponse<any>) => d.data).catch((e) => console.log(e))
}

export { getVisitors, deleteVisitor, deleteSelectedVisitors, getVisitorById, reSendInvitationAPI ,createVisitor, updateVisitor, EventDateListAPI ,shareInvitationWhatappAPI,sendInvite, getchapterList ,checkMobileAndMail ,sendInvitationAPI}
