import React, {useEffect, useState, useContext, useRef} from 'react'
import {PostHeader} from './PostHeader'
import {PostFooter} from './PostFooter'
import {Carousel, Image} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ViewPost} from './ViewPost'
import {DocumentPost} from './DocumentPost'
import {BirthDayPost} from './BirthDayPost'
import Zoom from 'react-medium-image-zoom'
import {checkDisplayValue} from '../../../../../utils'
import {useSelector} from 'react-redux'
import {apiUrl} from '../../../../../apiurl'
import {getAllPost, getAllPostWithFilter} from './_requests'
import {UserContext} from '../../../../../contextStore'
import InfiniteScroll from 'react-infinite-scroll-component'
import PostSkeleton from './PostSkeleton'
import PostCommentModal from '../post-comments/PostCommentModal'
import {ClassNames} from '@emotion/react'
import {LoadingPost} from './LoadingPost'
import {useReward} from 'react-rewards'

const MAX_LINES_TO_SHOW = 1
const API_URL = apiUrl
const useInterval = (callback: () => void) => {
  const callbackRef = useRef(() => {})

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    const timerId = setInterval(() => callbackRef.current(), 5000)
    return () => clearInterval(timerId)
  }, [])
}

const AnimationStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  zIndex: '1',
}

export function DefaultPost({filterId}: any) {
  const {userDetail, reload, chapterID} = useContext(UserContext)
  const [showDiv, setShowDiv] = useState(false)
  const [data, setData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState<any>(true)
  const [skip, setSkip] = useState(0)
  const limit = 10

  //fetch the api
  const fetchData = async (paraSkip: any = null, paraHasMore: any = null, paraData: any = null) => {
    let paraSkipReq = paraSkip != null ? paraSkip : skip
    let paraHasMoreReq = paraHasMore ? paraHasMore : hasMore
    let paraDataReq = paraData ? paraData : data
    setIsLoading(true)
    try {
      const responseData =
        filterId === 'all'
          ? await getAllPost(chapterID, paraSkipReq, limit)
          : await getAllPostWithFilter(filterId, paraSkipReq, limit,chapterID)
      if (responseData.length === 0) {
        setHasMore(paraHasMoreReq) // No more data to load
      } else {
        setHasMore(paraHasMoreReq)
        setData([...paraDataReq, ...responseData]) // Append new data to the existing data
        setSkip(paraSkipReq + limit) // Increment the skip value for the next API call
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Error -->:', error)
    }
  }

  useEffect(() => {
    setHasMore(true)
    setSkip(0)
  }, [])

  useEffect(() => {
    if (reload) {
      fetchData(0, true, [])
    }
  }, [reload])

  useEffect(() => {
    if (filterId !== 'all') {
      setData([])
      fetchData(0, true, [])
    } else {
      fetchData(0, true, [])
    }
  }, [filterId])

  const handleScroll = () => {
    const scrollY = window.scrollY
    const pageHeight = document.documentElement.scrollHeight
    const windowHeight = window.innerHeight
    const bottomOffset = 300 // Adjust the offset value as needed
    const isBottom = scrollY >= pageHeight - windowHeight - bottomOffset

    if (isBottom) {
      setPage((prevPage) => prevPage + 1)
    }

    setShowDiv(scrollY >= 1000)
  }

  const handleToshow = (file: any) => {
    if (file) {
      const url = `${API_URL}/containers/topicimages/download/${file}`
      window.open(url, '_blank')
    }
  }

  const handleDivClick = () => {
    // setIsLoading(true);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      // setIsLoading(false);
    }, 1000)
  }

  const {reward: rewardLeft, isAnimating: isAnimatingLeft} = useReward(
    `rewardLeft-${data?.map((item: any) => item?.id)}`,
    'confetti',
    {
      angle: 45,
      position: 'absolute',
      spread: 30,
      decay: 0.94,
      startVelocity: 45,
      elementCount: 105,
      elementSize: 7,
    }
  )
  const {reward: rewardRight, isAnimating: isAnimatingRight} = useReward(
    `rewardRight-${data?.map((item: any) => item?.id)}`,
    'confetti',
    {
      angle: 135,
      position: 'absolute',
      spread: 30,
      decay: 0.94,
      startVelocity: 45,
      elementCount: 105,
      elementSize: 7,
    }
  )

  // const {reward: balloonLeft, isAnimating: isAnimatingballoonLeft} = useReward(
  //   `balloonLeft-${data.map((item: any) => item.id)}`,
  //   'balloons',
  //   {
  //     angle: 45,
  //     position: 'absolute',
  //     spread: 50,
  //     decay: 0.99999,
  //     startVelocity: 10,
  //     elementCount: 5,
  //     elementSize: 7,
  //   }
  // )
  // const {reward: balloonRight, isAnimating: isAnimatingballoonRight} = useReward(
  //   `balloonRight-${data.map((item: any) => item.id)}`,
  //   'balloons',
  //   {
  //     angle: 135,
  //     position: 'absolute',
  //     spread: 50,
  //     decay: 0.99999,
  //     startVelocity: 10,
  //     elementCount: 5,
  //     elementSize: 7,
  //   }
  // )

  // useInterval(() => {
  //   if (!isAnimatingRight && !isAnimatingLeft) {
  //     rewardLeft()
  //     rewardRight()
  //     // balloonRight()
  //     // balloonLeft()
  //   }
  // })
  useEffect(() => {
    const leftElement = document.getElementById(`rewardLeft-${data?.map((item: any) => item?.id)}`);
    const rightElement = document.getElementById(`rewardRight-${data?.map((item: any) => item?.id)}`);
    
    const timerId = setTimeout(() => {
    if (!isAnimatingRight && !isAnimatingLeft && leftElement && rightElement) {
      rewardLeft();
      rewardRight();
     
    }
  }, 500); 
  return () => clearTimeout(timerId);
  }, [isAnimatingRight, isAnimatingLeft, data]);
  

  return (
    <>
      <div className='w-100 d-flex justify-content-center'>
        {showDiv && (
          <div className='show-after-scroll new-post-added cursor-pointer' onClick={handleDivClick}>
            {isLoading ? (
              <div className='loader'>Loading...</div>
            ) : (
              <div>
                <i className='fa-solid fa-arrow-up me-2 text-white'></i> Scroll To Top
              </div>
            )}
          </div>
        )}
      </div>
      {
        data?.length > 0 ? (
          <InfiniteScroll
            dataLength={data?.length}
            next={fetchData}
            hasMore={hasMore}
            onScroll={handleScroll}
            loader={
              <div className='d-flex justify-content-center'>
                <div className='spinner-border' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            }
            endMessage={
              <div className='d-flex justify-content-center'>
                <h4>No more posts to load.</h4>
              </div>
            }
            refreshFunction={() => {
              setSkip(0)
              setHasMore(true)
              setData([])
              fetchData()
            }}
            // height={700}
            initialScrollY={500}
            // pullDownToRefresh
            pullDownToRefreshThreshold={500}
            // pullDownToRefreshContent={
            //   // <h3 style={{textAlign: 'center'}}>&#8595; Pull down to refresh</h3>
            // }
          >
            {data?.map((item: any, index: any) => (
              <div
                className={`card ilead__card overflow-hidden mb-7 post-card ${
                  item?.isactive ? '' : 'hidePost'
                }`}
                key={index}
              >
                <PostHeader
                  item={item}
                  index={index}
                  name={item?.user?.name ?? ''}
                  companyName={item?.user?.companyname ?? ''}
                  profile={item?.user?.profile ?? ''}
                  poststypeannoucement={true}
                  isbookmarked={item?.isbookmarked ?? ''}
                  data={data}
                  setData={setData}
                  leaderGIColor={item?.leaderGIColor ?? ''}
                  chapter_id={item?.chapter_id ?? ''}
                  commentIndex={index}
                  commentData={item}
                  mainData={data}
                  setMainData={setData}
                ></PostHeader>
                <div className='card-body pt-3 pb-3 ps-0 pe-0'>
                  <div
                    className={`description-content ${
                      item?.description?.trim() == 'Top GI Performers' ||
                      item?.description?.trim() == 'Top BI Performers'
                        ? ' winner-post'
                        : null
                    }`}
                    style={{whiteSpace: 'pre-line', wordBreak: 'break-word'}}
                  >
                    <ViewPost item={item} />
                  </div>

                  {item?.usertopicmedia && item?.usertopicmedia?.length > 0 ? (
                    <div className='media mb-2'>
                      <Carousel controls={false}>
                        {item?.usertopicmedia?.map((image: any, imageIndex: any) => {
                          let itemLast = image?.file?.split('.')?.pop()
                          if (
                            itemLast == 'jpeg' ||
                            itemLast == 'jpeg' ||
                            itemLast == 'png' ||
                            itemLast == 'jpg'
                          ) {
                            return (
                              <Carousel.Item key={imageIndex}>
                                {item?.topic_id == 15 ? (
                                  <div
                                    style={AnimationStyle}
                                    className='position-absolute w-100 h-100'
                                  >
                                    <div className='popper'>
                                      <span id={`rewardLeft-${item?.id}`} />
                                    </div>
                                    <div className='popper'>
                                      <span id={`rewardRight-${item?.id}`} />
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <Zoom>
                                  <Image
                                    src={
                                      image?.file
                                        ? `${API_URL}/containers/topicimages/download/${image?.file}`
                                        : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                    }
                                    onError={({currentTarget}: any) => {
                                      currentTarget.onerror = null // prevents looping
                                      currentTarget.src = `${toAbsoluteUrl(
                                        './media/avatars/default-user.png'
                                      )}`
                                    }}
                                    className='w-100'
                                  />
                                </Zoom>
                              </Carousel.Item>
                            )
                          } else if (itemLast == 'pdf') {
                            return (
                              <Carousel.Item key={imageIndex}>
                                <Image
                                  src={'./media/ilead/pdf.png'}
                                  onError={({currentTarget}: any) => {
                                    currentTarget.onerror = null // prevents looping
                                    currentTarget.src = `${toAbsoluteUrl(
                                      './media/avatars/default-user.png'
                                    )}`
                                  }}
                                  onClick={() => handleToshow(image?.file)}
                                  className='w-100'
                                />
                              </Carousel.Item>
                            )
                          } else if (
                            itemLast == 'doc' ||
                            itemLast == 'docm' ||
                            itemLast == 'docx' ||
                            itemLast == 'dot'
                          ) {
                            return (
                              <Carousel.Item key={imageIndex}>
                                <Image
                                  src={'./media/ilead/docs2.webp'}
                                  onError={({currentTarget}: any) => {
                                    currentTarget.onerror = null // prevents looping
                                    currentTarget.src = `${toAbsoluteUrl(
                                      './media/avatars/default-user.png'
                                    )}`
                                  }}
                                  className='w-100'
                                  onClick={() => handleToshow(image?.file)}
                                />
                              </Carousel.Item>
                            )
                          } else if (
                            itemLast == 'xlsx' ||
                            itemLast == 'xlsm' ||
                            itemLast == 'xlsb' ||
                            itemLast == 'xltx'
                          ) {
                            return (
                              <Carousel.Item key={imageIndex}>
                                <Image
                                  src={'./media/ilead/sheets1.webp'}
                                  onError={({currentTarget}: any) => {
                                    currentTarget.onerror = null // prevents looping
                                    currentTarget.src = `${toAbsoluteUrl(
                                      './media/avatars/default-user.png'
                                    )}`
                                  }}
                                  className='w-100'
                                  onClick={() => handleToshow(image?.file)}
                                />
                              </Carousel.Item>
                            )
                          } else if (
                            itemLast == 'pptx' ||
                            itemLast == 'pptm' ||
                            itemLast == 'ppt'
                          ) {
                            return (
                              <Carousel.Item key={imageIndex}>
                                <Image
                                  src={'./media/ilead/ppt2.webp'}
                                  onError={({currentTarget}: any) => {
                                    currentTarget.onerror = null // prevents looping
                                    currentTarget.src = `${toAbsoluteUrl(
                                      './media/avatars/default-user.png'
                                    )}`
                                  }}
                                  className='w-100'
                                  onClick={() => handleToshow(image?.file)}
                                />
                              </Carousel.Item>
                            )
                          }
                        })}
                      </Carousel>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <PostFooter
                  option={false}
                  commentIndex={index}
                  commentData={item}
                  mainData={data}
                  setMainData={setData}
                ></PostFooter>
              </div>
            ))}
          </InfiniteScroll>
        ) : isLoading ? (
          <>
            <LoadingPost />
          </>
        ) : (
          <>
            <div className='card card-flush mb-4 post-card'>
              <div className='card-body pt-3 pb-3 ps-0 pe-0'>
                <div className='media' style={{maxHeight: 'inherit'}}>
                  <Image
                    src={toAbsoluteUrl('media/ilead/no-post.png')}
                    className='d-block w-100'
                  ></Image>
                </div>
              </div>
            </div>
          </>
        )
        // <>
        //   <LoadingPost />
        // </>
      }
    </>
  )
}
