import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Summarysession, SummarysessionsQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

// const API_URL = process.env.REACT_APP_THEME_API_URL
import {apiUrl} from '../../../../../apiurl'
const API_URL = apiUrl
const SUMMARYSESSION_URL = `${API_URL}/summarysession`
// const GET_SUMMARYSESSIONS_URL = `${API_URL}/users/query`
const GET_SUMMARYSESSIONS_URL = `${API_URL}/leaderpoints/getLeaderPointsList`
// const GET_SUMMARYSESSIONS_URL = `${API_URL}/leaderpoints/getSessionSummaryLeaderPointList`

const GROUP_DROPDOWN = `${API_URL}/groups`
const GET_AVERAGE_FEEDBACK = `${API_URL}/leaderpoints/getAverageFeedback`
const GET_ATTENDANCE_GRAPH = `${API_URL}/leaderpoints/getAttendanceGraph`
const GET_TRACKSHEET_COUNT = `${API_URL}/leaderpoints/gettracksheetcount`
const GET_INVITED_ATTENDED_JOINED_RATION = `${API_URL}/leaderpoints/getInvitedAttendedJoinedRatio`

const GET_INVITED_ATTENDED = `${API_URL}/leaderpoints/getSessionTable`
const TOPPERFORMERS_DATA = `${API_URL}/leaderpoints/getTopPerformers`
const CHAPTER_NAME = `${API_URL}/chapters`
// const SESSION_NAME = `${API_URL}/sessionsummaryreports`
const SESSION_DATES = `${API_URL}/eventdates`
const USER_URL = `${API_URL}/users?filter[where][isdelete]=0&filter[where][isactive]=1&filter[order]=name`

const getUserLeaderList = (): any => {
  return axios
    .get(USER_URL)
    .then((responce: any) => {
      return responce?.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

const GroupsDropDown = (): any => {
  return axios
    .get(
      `${GROUP_DROPDOWN}?filter[where][isdelete]=0&filter[where][chapter_id][inq]=3&filter[where][chapter_id][inq]=6`
    )
    .then((responce: any) => {
      return responce?.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

const GetAverageFeedback = (selectChapterId: any = "", selectSession: any = "", selectGroup: any = "", selectLeader: any = "") => {

  let query = `${GET_AVERAGE_FEEDBACK}?filter[where][leader_isactive]=1&filter[where][leader_isdelete]=0`

  if(selectChapterId){
    query = query + `&filter[where][chapter_id]=${selectChapterId}`
  }

  if(selectSession){
    query = query + `&filter[where][event_id]=${selectSession}`
  }

  if(selectGroup){
    query = query + `&filter[where][group_id]=${selectGroup}`
  }

  if(selectLeader){
    query = query + `&filter[where][leader_id]=${selectLeader}`
  }

  return axios
    .get(query)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

const GetAttendanceGraph = (selectChapterId: any, selectSession: any, selectGroup: any, selectLeader: any) => {

  let query = `${GET_ATTENDANCE_GRAPH}?filter[where][leader_isactive]=1&filter[where][leader_isdelete]=0`

  if (selectChapterId) {
    query = query + `&filter[where][chapter_id]=${selectChapterId}`
  }

  if (selectSession) {
    query = query + `&filter[where][event_id]=${selectSession}`
  }

  if (selectGroup) {
    query = query + `&filter[where][group_id]=${selectGroup}`
  }

  if (selectLeader) {
    query = query + `&filter[where][group_id]=${selectLeader}`
  }

  return axios
    .get(query)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

const GetTrackSheetCount = (selectChapterId: any, selectSession: any, selectGroup: any, selectLeader: any): any => {

  let query = `${GET_TRACKSHEET_COUNT}?filter[where][leader_isactive]=1&filter[where][leader_isdelete]=0`

  if (selectChapterId) {
    query = query + `&filter[where][chapter_id]=${selectChapterId}`
  }

  if (selectSession) {
    query = query + `&filter[where][event_id]=${selectSession}`
  }

  if (selectGroup) {
    query = query + `&filter[where][group_id]=${selectGroup}`
  }

  return axios
    .get(
      // `${GET_TRACKSHEET_COUNT}?filter[where][leader_isactive]=1&filter[where][leader_isdelete]=0&filter[where][chapter_id]=${selectChapterId}&filter[where][month][inq]=[${selectMonth}]&filter[where][year]=${selectYear}`
      query
    )
    .then((responce: any) => {
      return responce?.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}


const GetInvitedAttendedJoinedRatio = (selectChapterId: any, selectSession: any, selectGroup: any, selectLeader: any) => {

  let query = `${GET_INVITED_ATTENDED_JOINED_RATION}?filter[where][leader_isactive]=1&filter[where][leader_isdelete]=0`

  if (selectChapterId) {
    query = query + `&filter[where][chapter_id]=${selectChapterId}`
  }

  if (selectSession) {
    query = query + `&filter[where][event_id]=${selectSession}`
  }

  if (selectGroup) {
    query = query + `&filter[where][group_id]=${selectGroup}`
  }

  if(selectLeader){
    query = query + `&filter[where][leader_id]=${selectLeader}`
  }

  return axios
    .get(query)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

const GetInvitedAttended = (selectChapterId: any, selectSession: any, selectGroup: any, selectLeader: any) => {

  let query = `${GET_INVITED_ATTENDED}?filter[where][leader_isactive]=1&filter[where][leader_isdelete]=0`

  if (selectChapterId) {
    query = query + `&filter[where][chapter_id]=${selectChapterId}`
  }

  if (selectSession) {
    query = query + `&filter[where][event_id]=${selectSession}`
  }

  if (selectGroup) {
    query = query + `&filter[where][group_id]=${selectGroup}`
  }

  if (selectLeader) {
    query = query + `&filter[where][leader_id]=${selectLeader}`
  }

  return axios
    .get(
      // `${GET_INVITED_ATTENDED}?filter[where][leader_isactive]=1&filter[where][leader_isdelete]=0&filter[where][chapter_id]=${selectChapterId}&filter[where][month][inq]=[${selectMonth}]&filter[where][year]=${selectYear}`
      query
    )
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

const TopPerformersData = (selectChapterId: any, selectSession: any, selectGroup: any, selectLeader: any): any => {

  let query = `${TOPPERFORMERS_DATA}?filter[where][leader_isactive]=1&filter[where][leader_isdelete]=0`

  if (selectChapterId) {
    query = query + `&filter[where][chapter_id]=${selectChapterId}`
  }

  if (selectSession) {
    query = query + `&filter[where][event_id]=${selectSession}`
  }

  if (selectGroup) {
    query = query + `&filter[where][group_id]=${selectGroup}`
  }

  if (selectLeader) {
    query = query + `&filter[where][leader_id]=${selectLeader}`
  }

  return axios
    .get(
      // `${TOPPERFORMERS_DATA}?filter[where][leader_isactive]=1&filter[where][leader_isdelete]=0&filter[where][chapter_id]=${selectChapterId}&filter[where][month]=[${selectMonth}]&filter[where][year]=${selectYear}`
      query
    )
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

const ChapterName = () => {
  return axios
    .get(
      `${CHAPTER_NAME}?filter[where][isdelete]=0&filter[where][id][inq]=3&filter[where][id][inq]=6`
    )
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

const SessionDates = (selectChapterId:any = "") => {
  let query = SESSION_DATES + `?filter[order]=event_date DESC`;
  if (selectChapterId) {
    query = query + `&filter[where][chapter_id]=${selectChapterId}`
  }
  return axios
    .get(query)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}



const getSummarysessions = (query: string): Promise<SummarysessionsQueryResponse> => {
  return axios
    .get(`${GET_SUMMARYSESSIONS_URL}?isWeb=True&${query}`)
    .then((d: AxiosResponse<any>) => d.data).catch((e) => console.log(e))
}

const getSummarysessionById = (id: ID): Promise<Summarysession | undefined> => {
  return axios
    .get(`${SUMMARYSESSION_URL}/${id}`)
    // .then((response: AxiosResponse<Response<Summarysession>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const createSummarysession = (summarysession: Summarysession): Promise<Summarysession | undefined> => {
  return axios
    .put(SUMMARYSESSION_URL, summarysession)
    // .then((response: AxiosResponse<Response<Summarysession>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const updateSummarysession = (summarysession: Summarysession): Promise<Summarysession | undefined> => {
  return axios
    .post(`${SUMMARYSESSION_URL}/${summarysession.id}`, summarysession)
    // .then((response: AxiosResponse<Response<Summarysession>>) => response.data).catch((e) => console.log(e))
    .then((response: Response<any>) => response.data).catch((e) => console.log(e))
}

const deleteSummarysession = (summarysessionId: ID): Promise<void> => {
  return axios.delete(`${SUMMARYSESSION_URL}/${summarysessionId}`).then(() => {})
}

const deleteSelectedSummarysessions = (summarysessionIds: Array<ID>): Promise<void> => {
  const requests = summarysessionIds.map((id) => axios.delete(`${SUMMARYSESSION_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getSummarysessions, deleteSummarysession, deleteSelectedSummarysessions, 
  getSummarysessionById, createSummarysession, updateSummarysession, GroupsDropDown, 
        GetAverageFeedback, GetAttendanceGraph, GetTrackSheetCount, GetInvitedAttendedJoinedRatio, 
        GetInvitedAttended, TopPerformersData, ChapterName, SessionDates, getUserLeaderList
}
