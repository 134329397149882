import React from "react";
import { useMemo, useState, useEffect, useContext, useRef } from 'react'
import { sendInvite, checkMobileAndMail, EventDateListAPI, sendInvitationAPI } from "./visitors-list/core/_requests";
import { getChaptersList } from "../event-management/events-list/core/_requests";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { UserContext } from './../../../contextStore'
import moment from "moment";
import { formatDate } from "../../../utils";
import { useQueryResponse } from "./visitors-list/core/QueryResponseProvider";
import { Modal,Button } from "react-bootstrap";

const phoneRegExp = /^\d{10}$/

// const SendInvitation = () => {
const SendInvitation = ({ closebutton}: any) => {
    const closeButton =useRef<any>(null)
    const [eventDetail, setEventDetail] = useState([])
    const { refetch } = useQueryResponse()
    const [chapterId ,setChapterId] =useState("") 
    const [showWhatapp, setShowWhatapp] = useState(false);
    const currentDate = moment().format('YYYY-MM-DD')
    const [chapterList, setChapterList] = useState<any>([
        { label: "All", value: "" }
    ])

    const handleClose = () => setShowWhatapp(false);
  const handleShow = () => setShowWhatapp(true);

    const [isMob, setMob] = useState(false)
    const [isMail, setMail] = useState(false)

    const { setAuth, setMessage, setChapterID,refreshEventTable  ,chapterID} = useContext(UserContext);

    const getChapter = async () => {
        await getChaptersList().then((response: any) => {
            if (response && response?.length > 0) {
                setChapterList(() => response ?? [])
                // let clist = response?.map((x: any) => {
                //     return { label: x?.name, value: x?.id }
                // }) as any ?? []
                // clist.unshift({ label: "All", value: "" })
                // setChapterList(clist)
                // console.log("chapters received 41 = ", clist)
            }
            // response 
        }).catch((error: any) => {
            console.log("error", error)
        })

        
    }
    const getevent = async ()=> {
        try {
            const eventListing = await EventDateListAPI(chapterId, currentDate)
            setEventDetail(() => eventListing ?? [])
            // setIsLoading(false)
          } catch (error) {
            console.error('Error -->:', error)
            // setIsLoading(false)
          }
    }

    useEffect(() => {
        getChapter()
        getevent()
    }, [])

    



    const initialValues = {
        chapter_id: '',
        event_id: '',
        name: '',
        cellnumber: '',
        email: '',
        companyname: ''    
    }

    console.log("initialValues 46 = ", initialValues);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        cellnumber: Yup.string()
          .required('Phone number is required')
          .matches(/^[6789]\d{9}$/, 'Invalid phone number'),
        // email: Yup.string().email().required('Email is required'),
        email: Yup.string().email(),
        companyname: Yup.string(),
        // companyname: Yup.string().required('Company Name is required'),
        chapter_id : Yup.string().required('chapter Name is required'),
        event_id : Yup.string().required('Event Date is required'),
      })

    // console.log("addEventSchema 46 = ", addEventSchema);

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                //console.log("Data add = ", values);
               
                // console.log("107 Add");
                await sendInvitationAPI({...values,comapanyname:values?.companyname ?? values?.companyname  }).then((res:any)=>{
                    //console.log("res",res.data)
                    if(res?.data?.id ){
                      resetForm()
                      handleShow()
                      setShowWhatapp(true)
                      refetch()
                    //   navigate('/visitor-panel')
                        // closeButton.current.click()
                      setMessage('success', 'Invitations send successfully')
                      // handleClose()
                    }
                  }).catch((error:any)=>{
                    console.log("error",error)
                    if (error?.response?.data?.error?.message) {
                      console.log("error?.response?",error?.response?.status)
                      if(error?.response?.status == 500 )
                      {
                      setMessage('error', 'Something went wrong')
                      }else{
        
                        setMessage('error', error?.response?.data?.error?.message)
                      }
                    } else {
                      setMessage('error', 'Something went wrong')
                    }
                  })


            } catch (error) {
                //   setMessage('error', 'Something went wrong')
                console.log("erorr = ", error);
            }
        },
    })

    useEffect(() => {
      getevent()
  }, [formik?.values?.chapter_id])

    //console.log("formik 46 = ", formik);

    return (
      <>
        <div
          className='modal fade visitor-details'
          id='sendinvitation'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex={-1}
          aria-labelledby='staticBackdropLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog me-0 mb-0 mt-0 border-radius-0'>
            <div className='modal-content '>
              <div className='modal-header d-block border-bottom border-gray-300'>
                <div className='d-flex'>
                  <h5 className='modal-title h4 text-primary'  id='staticBackdropLabel'>
                    Visitor Invitation
                  </h5>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    data-bs-target="#sendinvitation"
                    ref={closeButton}
                    onClick={()=>{
                        formik.resetForm()
                    }}
                  ></button>
                </div>
                <p className='mb-0 mt-5 fw-semibold' 
                
                >
                  {' '}
                  Use Below Form To Send An Invitation To Visitor.{' '}
                </p>
              </div>
              {/* <form action=""> */}
              <form onSubmit={formik.handleSubmit}>
                <div className='modal-body'>
                  <div className='col-md-12 mb-8'>
                    <label className='ps-0'>
                      {' '}
                      Chapter <span className='text-danger'> * </span>
                    </label>
                    <select
                      className='form-select ps-0'
                      aria-label='Default select example'
                      onChange={(e: any) => {
                        formik.setFieldValue('chapter_id', e.target.value.trim())
                        setChapterId( e.target.value.trim())
                        formik.setFieldValue('event_id', "")
                      }}
                      // value={formik.values.chapter}
                    >
                      {/* <option selected>Select Chapter</option>
                                        <option value="2">Eklavya</option> */}
                        <option selected={formik?.values?.chapter_id == ""} value=''>Select Chapter</option> 
                      {chapterList.map((x: any) => {
                        return (
                          <option
                            selected={formik?.values?.chapter_id == x?.id}
                            value={x?.id}
                          >
                            {x?.name}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.chapter_id && formik?.errors.chapter_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors?.chapter_id}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-12 col-lg-12'>
                    <div className='col-md-12 mb-8'>
                      <label className='ps-0'>
                        {' '}
                        Event Date <span className='text-danger'> * </span>
                      </label>
                      <select
                        className='form-select ps-0'
                        onChange={(e: any) => {
                          formik.setFieldValue('event_id', e.target.value.trim())
                        }}
                        aria-label='Default select example'
                        
                      >
                        <option selected={formik?.values?.event_id == ""} value=''>
                          Select Event Date
                        </option>
                        {eventDetail.map((x: any) => {
                          return (
                            <option selected={formik?.values?.event_id == x?.id} value={x?.id}>
                              {formatDate(x?.event_date)}
                            </option>
                          )
                        })}
                      </select>
                      {formik.touched.event_id && formik?.errors.event_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors?.event_id}</span>
                        </div>
                      </div>
                    )}
                    </div>
                  </div>

                  <div className='col-md-12 col-lg-12'>
                    <div className='form-floating mb-3'>
                      <input
                        type='text'
                        className='form-control ps-0'
                        id='floatingInput'
                        placeholder=''
                        
                        value={formik.values.name}
                        onChange={(e) => {
                          formik.setFieldValue('name', e.target.value.trimStart())
                        }}
                      />
                      <label className='ps-0'>
                        {' '}
                        Name <span className='text-danger'> * </span>
                      </label>
                    </div>
                    {formik.touched.name && formik?.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors?.name}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='col-md-12 col-lg-12'>
                    <div className='form-floating mb-3'>
                      <input
                        type='text'
                        className='form-control ps-0'
                        id='floatingInput'
                        placeholder=''
                        
                        value={formik.values.cellnumber}
                        onChange={(e) => {
                          formik.setFieldValue('cellnumber', e.target.value.trim())
                        }}
                      />
                      <label className='ps-0'>
                        {' '}
                        Mobile Number <span className='text-danger'> * </span>
                      </label>
                    </div>
                    {formik.touched.cellnumber && formik?.errors.cellnumber && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors?.cellnumber}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='col-md-12 col-lg-12'>
                    <div className='form-floating mb-3'>
                      <input
                        type='text'
                        className='form-control ps-0'
                        id='floatingInput'
                        placeholder='Enter Email '
                        
                        value={formik.values.email}
                        onChange={(e:any) => {
                          formik.setFieldValue('email', e.target.value.trimEnd())
                        }}
                      />
                      <label className='ps-0'>
                        {' '}
                        Email 
                      </label>
                    </div>
                    {formik.touched.email && formik?.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors?.email}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='col-md-12 col-lg-12'>
                    <div className='form-floating mb-3'>
                      <input
                        type='text'
                        className='form-control ps-0'
                        id='floatingInput'
                        placeholder=''
                        
                        value={formik.values.companyname}
                        onChange={(e) => {
                          formik.setFieldValue('companyname', e.target.value.trimStart())
                        }}
                      />
                      <label className='ps-0'>
                        {' '}
                        Company Name 
                      </label>
                    </div>
                    {formik.touched.companyname && formik?.errors.companyname && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors?.companyname}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='modal-footer position-absolute bottom-0 w-100 pe-5'>
                  {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                  <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting}>
                    Submit
                  </button>
                </div>
                </div>
                
                
              </form>
            </div>
          </div>
        </div>

        <Modal
        show={showWhatapp}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Invitation has been sent successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Share on Whatapp : <i className="bi bi-whatsapp h1" style={{color:"green"}}></i> </h3>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>{
            closeButton.current.click()
            // setTimeout(()=>{
              handleClose()
            // },500)
          }}>
            Done
          </Button>
          {/* <Button variant="primary">Understood</Button> */}
        </Modal.Footer>
      </Modal>

        
      </>
    )


}
export default SendInvitation