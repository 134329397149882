import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {visitorsColumns} from './columns/_columns'
import {Visitor} from '../core/_models'
import {VisitorsListLoading} from '../components/loading/VisitorsListLoading'
import {VisitorsListPagination} from '../components/pagination/VisitorsListPagination'
import { KTCardBody, defaultVisitors ,initialQueryState } from '../../../../../_metronic/helpers'
import { useQueryRequest } from '../core/QueryRequestProvider'
// import {KTCardBody} from '../../../../../../_metronic/helpers'

const VisitorsTable = () => {
  const visitors = useQueryResponseData()
  // const visitors = defaultVisitors
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => visitors, [visitors])
  // const data = useMemo(() => events, [events])
  const columns = useMemo(() => visitorsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const{state,updateState} = useQueryRequest() as any
  //console.log("state 21 = ", state);

  return (
    <KTCardBody className='p-0'>
      <div className='table-responsive mb-8 data-table-report'>
        <table
          id='kt_table_visitors'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<Visitor>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>

          <thead className='table-sub-hdr'>

            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {/* {headers.map((column: ColumnInstance<Sessionattendance>) => (
                <input type="text"/>
              ))} */}
              <th className='p-3'> <input type="date" className="form-control mb-3" placeholder="" 
                  value={state?.filter?.event_date}
                  onChange={(e:any)=>{
                    let filter = state?.filter ?? {}
                    filter['event_date'] = e.target.value
                    updateState({...initialQueryState,...{filter:filter}})
                  }} /> 
              </th>
              <th className='p-3'> <input type="text" className="form-control mb-3" placeholder=" " 
                  value={state?.filter?.invitee_name}
                  onChange={(e:any)=>{
                    let filter = state?.filter ?? {}
                    filter['invitee_name'] = e.target.value
                    updateState({...initialQueryState,...{filter:filter}})
                  }} /> 
              </th>
              <th className='p-3'> <input type="text" className="form-control mb-3" placeholder="" 
                  value={state?.filter?.cell_no}
                  onChange={(e:any)=>{
                    let filter = state?.filter ?? {}
                    filter['cell_no'] = e.target.value
                    updateState({...initialQueryState,...{filter:filter}})
                  }}/> 
              </th>
              <th className='p-3'> <input type="text" className="form-control mb-3" placeholder="" 
                  value={state?.filter?.email}
                  onChange={(e:any)=>{
                    let filter = state?.filter ?? {}
                    filter['email'] = e.target.value
                    updateState({...initialQueryState,...{filter:filter}})
                  }}/> 
              </th>
              {/* <th className='p-3'> <select className="form-select form-select-lg mb-3 mt-2" aria-label=".form-select-lg example">
                                    <option selected>All</option>
                                    <option value="1">Trustee</option>
                                    <option value="2">Eklavya</option>
                                </select> </th> */}
              
                                {/* <th className='p-3'> <input type="text" className="form-control mb-3" placeholder=" " /> </th> */}
              <th className='p-3'> <input type="text" className="form-control mb-3" placeholder="" 
                  value={state?.filter?.company}
                  onChange={(e:any)=>{
                    let filter = state?.filter ?? {}
                    filter['company'] = e.target.value
                    updateState({...initialQueryState,...{filter:filter}})
                  }}/> 
              </th>
              <th className='p-3'> &nbsp; </th>
            </tr>
          </thead>
  
          <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Visitor>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <VisitorsListPagination />
      {isLoading && <VisitorsListLoading />}
    </KTCardBody>
  )
}

export {VisitorsTable}
