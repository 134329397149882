import {useEffect, useMemo, useState, useRef, useContext} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {useReactToPrint} from 'react-to-print'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../core/QueryResponseProvider'
import {visitorpanelsColumns} from './columns/_columns'
import {Visitorpanel} from '../core/_models'
import {VisitorpanelsListLoading} from '../components/loading/VisitorpanelsListLoading'
import {VisitorpanelsListPagination} from '../components/pagination/VisitorpanelsListPagination'
import {KTCardBody, defaultVisitorpanels} from '../../../../../_metronic/helpers'
// import {KTCardBody} from '../../../../../../_metronic/helpers'
import Select from 'react-select'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {initialQueryState} from '../../../../../_metronic/helpers'
import {getChapterList} from '../../../auth/core/_requests'

import jsPDF from 'jspdf'
import 'jspdf-autotable'
import html2canvas from 'html2canvas'
import * as XLSX from 'xlsx'
import {checkDisplayValue, formatDate} from '../../../../../utils'
import moment from 'moment'
import { accessUser } from '../../../../../utils/privileges'
import { UserContext } from '../../../../../contextStore'

const options = [{value: 'eklavya', label: 'Eklavya'}]

const VisitorpanelsTable = () => {
  const visitorpanels = useQueryResponseData()
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const { userDetail, setMessage ,chapterID } = useContext(UserContext)
  // const visitorpanels = defaultVisitorpanels
  // const isLoading = useQueryResponseLoading()
  const data = useMemo(() => visitorpanels, [visitorpanels])
  const columns = useMemo(() => visitorpanelsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const userId =  userDetail?.id ?  userDetail?.id : ""
  const tableREf = useRef(null)

  const [chapterList, setchapterList] = useState([])
  const [reqData, setReqData] = useState<any>({
    event_date: '',
    invitee_name: '',
    name: '',
    cell_no: '',
    company: '',
    chapter_name: '',
    isvisited: '',
    isjoin: '',
  })

  const getchpterList = async () => {
    try {
      const res = await getChapterList()
      setchapterList(res?.data)
    } catch (error) {}
  }

  useEffect(() => {
    getchpterList()
  }, [])
  const filterData = () => {
    updateState({
      filter: reqData,
      ...initialQueryState,
    })
  }
  useEffect(() => {
    filterData()
  }, [reqData])
  //console.log('chapterList', chapterList)

  const PrintData = useReactToPrint({
    content: () => tableREf.current,
    documentTitle: 'visitor Panel',
    // onAfterPrint()=>
  })

  // const printData = () =>{
  //   tableREf.current.print();
  // }
  const [selectedOption, setSelectedOption] = useState<any>(null)

  const exportPdf = () => {
    const doc = new jsPDF()
    // doc.autoPrint("pppppppp")
    // doc.auto
    // doc.autoTable({ head: headers, body: rows });

    doc.save('products.pdf')
  }
  function convertTableToPDF() {
    try {
      const doc = new jsPDF() as any

      // Define the source table
      let table = document.getElementById('copy_kt_table_visitorpanels') as any

      // Make the table briefly visible
      table.style.display = 'table'

      // Convert the table to a data array
      const tableData = [] as any
      const rows = table.querySelectorAll('tr')
      rows.forEach(function (row: any) {
        const rowData = [] as any
        const cells = row.querySelectorAll('td, th')
        cells.forEach(function (cell: any) {
          rowData.push(cell.textContent)
        })
        tableData.push(rowData)
      })

      // Set table column widths
      const columnWidths = [25, 25, 20, 20, 30, 20, 20, 20]

      // Create the PDF table
      doc.autoTable({
        head: [tableData[0]], // First row as header
        body: tableData.slice(1), // Exclude header row
        theme: 'grid',
        margin: {top: 9},
        startY: 20,
        columnStyles: {
          0: {columnWidth: columnWidths[0]},
          1: {columnWidth: columnWidths[1]},
          2: {columnWidth: columnWidths[2]},
          3: {columnWidth: columnWidths[3]},
          4: {columnWidth: columnWidths[4]},
          5: {columnWidth: columnWidths[5]},
          6: {columnWidth: columnWidths[6]},
          7: {columnWidth: columnWidths[7]},
          8: {columnWidth: columnWidths[8]},
        },
      })

      // Save the PDF with a file name
      table.style.display = 'none'
      doc.save('visitorPanel.pdf')
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_visitorpanels') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }

  const copyTable = () => {
    try {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_visitorpanels') as any

      // Make the table briefly visible
      table.style.display = 'table'

      // Create a range object to select the table content
      const range = document.createRange()
      range.selectNode(table)

      // Create a selection object and add the range
      const selection = window.getSelection() as any
      selection.removeAllRanges()
      selection.addRange(range)

      // Execute the copy command
      document.execCommand('copy')

      // Deselect the content
      selection.removeAllRanges()

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'

      // Optionally, provide feedback to the user
      
      alert('Table data copied to clipboard')

    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_visitorpanels') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }

  function ExportToExcel() {
    try {
      // Acquire Data (reference to the HTML table)
      let table = document.getElementById('copy_kt_table_visitorpanels') as any

      // Make the table briefly visible
      table.style.display = 'table'

      // Extract Data (create a workbook object from the table)
      var workbook = XLSX.utils.table_to_book(table)

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'

      // Process Data (add a new row)
      var ws = workbook.Sheets['Sheet1']
      // XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});

      // Package and Release Data (`writeFile` tries to write and save an XLSB file)
      XLSX.writeFile(workbook, 'visitorPanel.xlsb')
      table.style.display = 'none'
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_visitorpanels') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }

  function convertTableToCSV() {
    try {
      let table = document.getElementById('copy_kt_table_visitorpanels') as any

      // Make the table briefly visible
      table.style.display = 'table'

      const rows = table.querySelectorAll('tr') as any
      let csv = []

      for (const row of rows) {
        const cells = row.querySelectorAll('td, th') as any
        const rowData = Array.from(cells).map((cell: any) => cell.textContent)
        csv.push(rowData.join(','))
      }

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'

      const csvContent = 'data:text/csv;charset=utf-8,' + csv.join('\n')
      const encodedURI = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedURI)
      link.setAttribute('download', 'visitorPanel.csv')
      document.body.appendChild(link)
      link.click()
      table.style.display = 'none'
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_visitorpanels') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }
  const convertTableToPrint = () => {
    try {
      // Define the source table
      let table = document.getElementById('copy_kt_table_visitorpanels') as any

      // Make the table briefly visible
      table.style.display = 'table'

      html2canvas(document.querySelector('#copy_kt_table_visitorpanels') as any).then(function (
        canvas: any
      ) {
        let imgWidth = 210
        let pageHeight = 290
        let imgHeight = (canvas.height * imgWidth) / canvas.width
        let heightLeft = imgHeight

        let doc = new jsPDF('p', 'mm')
        let position = 0
        let pageData = canvas.toDataURL('image/jpeg', 1.0)
        let imgData = encodeURIComponent(pageData)
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        doc.setLineWidth(5)
        doc.setDrawColor(255, 255, 255)
        doc.rect(0, 0, 210, 295)
        heightLeft -= pageHeight

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          doc.addPage()
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
          doc.setLineWidth(5)
          doc.setDrawColor(255, 255, 255)
          doc.rect(0, 0, 210, 295)
          heightLeft -= pageHeight
        }
        doc.autoPrint()
        //This is a key for printing
        doc.output('dataurlnewwindow')
      })

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_visitorpanels') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }

  return (
    <KTCardBody className='p-0'>
      <div className='row mx-0 d-sm-block d-md-none mb-8'>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Event Date </h3>
              <input type='date' className='form-control mb-3' 
              onChange={(e: any) => {
                //console.log('ccc', e?.target?.value?.trimStart())
                setReqData({...reqData, ...{event_date: e?.target?.value?.trimStart()}})
              }}
              value={reqData?.event_date}
              placeholder=' ' />
            </div>

            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Invitee Name /</h3>
              <input type='text' className='form-control mb-3'
              onChange={(e: any) => {
                setReqData({...reqData, ...{invitee_name: e?.target?.value?.trimStart()}})
              }}
              placeholder=' ' />
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Invited By </h3>
              <input type='text' className='form-control mb-3'
              onChange={(e: any) => {
                setReqData({...reqData, ...{name: e?.target?.value?.trimStart()}})
              }}
              placeholder=' ' />
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Cell No </h3>
              <input type='text' className='form-control mb-3'
               onChange={(e: any) => {
                setReqData({...reqData, ...{cell_no: e?.target?.value?.trimStart()}})
              }}
              placeholder=' ' />
            </div>

            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Company </h3>
              <input type='text' className='form-control mb-3'
              onChange={(e: any) => {
                setReqData({...reqData, ...{company: e?.target?.value?.trimStart()}})
              }}
              placeholder='' />
            </div>

            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Chapter </h3>
              <Select
                required
                defaultValue={selectedOption}
                onChange={(selectedOption: any) => {
                  setReqData({...reqData, ...{chapter_name: selectedOption?.value?.trimStart()}})
                  //console.log("selectedOption",selectedOption)
                  setSelectedOption(selectedOption)
                }}
                value={selectedOption}
                options={[...[{value:'',label:"Selete Chapter"}],...chapterList.map((item: any) => {
                  return {
                    value: item?.name,
                    label:item?.name
                  };
                })]}
                className='mb-5 text-dark'
              />
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Invited Status </h3>
              <select
                className='form-select form-select-lg mb-3 mt-2'
                aria-label='.form-select-lg example'
                onChange={(e:any)=>{
                  setReqData({...reqData, ...{isvisited: e?.target?.value?.trimStart()}})
                }}
              >
               <option selected={reqData?.isvisited == ""} value=""> - </option>
                <option  selected={reqData?.isvisited == "1"} value="1">Invite</option>
                <option  selected={reqData?.isvisited == "2"} value="2">Attended</option>
                <option  selected={reqData?.isvisited == "3"} value="3">Not Attended</option>
              </select>{' '}
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3'>
              <h3 className='text-primary fs-5 fw-bold'> Membership Status</h3>
              <select
                className='form-select form-select-lg mb-3 mt-2'
                aria-label='.form-select-lg example'
                onChange={(e:any)=>{
                  setReqData({...reqData, ...{isjoin: e?.target?.value?.trimStart()}})
                }}
              >
                <option selected={reqData?.isjoin == ""} value=""> - </option>
                <option selected={reqData?.isjoin == "1"} value="1">Empty</option>
                <option selected={reqData?.isjoin == "2"} value="2">Interested</option>
                <option selected={reqData?.isjoin == "3"} value="3">Not Interested</option>
                <option selected={reqData?.isjoin == "4"} value="4">Joined</option>
              </select>{' '}
            </div>
          </div>
      <div className='table-responsive mb-8 data-table-report'>
        <div className='d-flex justify-content-start mb-3 mt-6 '>
          <button
            type='button'
            className='btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-primary'
            onClick={() => {
              copyTable()
            }}
          >
            Copy
          </button>
          <button
            type='button'
            className='btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-success'
            onClick={() => {
              ExportToExcel()
            }}
          >
            Excel
          </button>
          <button
            type='button'
            className='btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-warning'
            onClick={() => {
              convertTableToCSV()
            }}
          >
            CSV
          </button>
          <button
            type='button'
            className='btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-danger'
            onClick={() => {
              convertTableToPDF()
            }}
          >
            PDF
          </button>
          <button
            type='button'
            className='btn px-3 px-sm-5 py-2 me-1 me-sm-3 btn-info'
            onClick={() => {
              convertTableToPrint()
            }}
          >
            Print
          </button>
        </div>
        <table
          ref={tableREf}
          id='kt_table_visitorpanels'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<Visitorpanel>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <thead className='table-sub-hdr'>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {/* {headers.map((column: ColumnInstance<Sessionattendance>) => (
    <input type="text"/>
  ))} */}
              <th className='p-3'>
                {' '}
                <input
                  type='date'
                  className='form-control mb-3'
                  onChange={(e: any) => {
                    //console.log('ccc', e?.target?.value?.trimStart())
                    setReqData({...reqData, ...{event_date: e?.target?.value?.trimStart()}})
                  }}
                  value={reqData?.event_date}
                  placeholder=' '
                />{' '}
              </th>
              <th className='p-3'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  onChange={(e: any) => {
                    setReqData({...reqData, ...{invitee_name: e?.target?.value?.trimStart()}})
                  }}
                  placeholder=' '
                />{' '}
              </th>
              <th className='p-3'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  onChange={(e: any) => {
                    setReqData({...reqData, ...{name: e?.target?.value?.trimStart()}})
                  }}
                  placeholder=' '
                />{' '}
              </th>
              <th className='p-3'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  onChange={(e: any) => {
                    setReqData({...reqData, ...{cell_no: e?.target?.value?.trimStart()}})
                  }}
                  placeholder=' '
                />{' '}
              </th>
              <th className='p-3'>
                {' '}
                <input
                  type='text'
                  className='form-control mb-3'
                  onChange={(e: any) => {
                    setReqData({...reqData, ...{company: e?.target?.value?.trimStart()}})
                  }}
                  placeholder=''
                />{' '}
              </th>

              {/* <th className='p-3'> 
              
              <Select
                    required
                    defaultValue={selectedOption}
                    onChange={(selectedOption: any) => {
                      setSelectedOption(selectedOption)
                    }}
                    options={chapterList}
                    className='mb-5 text-dark'
                  />
               </th> */}
               <th className='p-3'>  
               {
                accessUser.panelsuperadmin.includes(userId) ? (<select className="form-select form-select-lg mb-3 mt-2" aria-label=".form-select-lg example"
                onChange={(e:any)=>{
                 //console.log("3",e?.target?.value)
                 setReqData({...reqData, ...{chapter_name: e?.target?.value?.trimStart()}})
                 setSelectedOption({value: e?.target?.value?.trimStart(), label: e?.target?.value?.trimStart()})
               }}>
                 <option selected value=""> - </option>
                 {chapterList.map((item:any,i:any)=>{
                   return(<option  selected={reqData?.chapter_name == item.name} value={item.name}>{item.name}</option>)
                 })}
               </select>):(<></>)
               }
                </th>
              <th className='p-3'> <select className="form-select form-select-lg mb-3 mt-2" aria-label=".form-select-lg example"
               onChange={(e:any)=>{
                setReqData({...reqData, ...{isvisited: e?.target?.value?.trimStart()}})
              }}>
                <option selected={reqData?.isvisited == ""} value=""> - </option>
                <option  selected={reqData?.isvisited == "1"} value="1">Invite</option>
                <option  selected={reqData?.isvisited == "2"} value="2">Attended</option>
                <option  selected={reqData?.isvisited == "3"} value="3">Not Attended</option>
              </select> </th>
              <th className='p-3'> <select className="form-select form-select-lg mb-3 mt-2" aria-label=".form-select-lg example" 
              onChange={(e:any)=>{
                setReqData({...reqData, ...{isjoin: e?.target?.value?.trimStart()}})
              }}
              >
                <option selected={reqData?.isjoin == ""} value=""> - </option>
                <option selected={reqData?.isjoin == "1"} value="1">Empty</option>
                <option selected={reqData?.isjoin == "2"} value="2">Interested</option>
                <option selected={reqData?.isjoin == "3"} value="3">Not Interested</option>
                <option selected={reqData?.isjoin == "4"} value="4">Joined</option>
              </select> </th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Visitorpanel>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={8}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/*  table for copy, print , excel, csv and pdf with filter haeder which display none */}
        <table
          // ref={tableREf}
          id='copy_kt_table_visitorpanels'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable table-striped  no-footer'
          {...getTableProps()}
          style={{display: 'none'}}
        >
          <thead>
            <tr className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0 border-bottom border-gray-100'>
              {headers.map((column: ColumnInstance<Visitorpanel>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                //console.log('=====row>', row)
                return (
                  <tr key={i}>
                    <td>
                      {row?.original?.event_date
                        ? moment(row?.original?.event_date).format('DD-MM-YYYY')
                        : '-'}
                    </td>
                    <td>{checkDisplayValue(row?.original?.invitee_name)}</td>
                    <td>{checkDisplayValue(row?.original?.name)}</td>
                    <td>{checkDisplayValue(row?.original?.cell_no)}</td>
                    <td>{checkDisplayValue(row?.original?.company)}</td>
                    <td>{checkDisplayValue(row?.original?.chapter_name)}</td>
                    <td>{row?.original?.isvisited == 1 ? 'Invite' : row?.original?.isvisited == 2 ? 'Attended' : 'Not Attended'}</td>
                    <td>{row?.original?.isjoin == 1 ? 'Empty': row?.original?.isjoin == 2 ? 'Interested' : 'Not Interested'}</td>
                    
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={8}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <VisitorpanelsListPagination />
      {isLoading && <VisitorpanelsListLoading />}
    </KTCardBody>
  )
}

export {VisitorpanelsTable}
