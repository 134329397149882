import React, {useCallback, useContext, useRef, useState} from 'react'
import {Button, Form, InputGroup} from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'
import {SearchLeaderListing} from './SearchLeaderListing'
import {addUserTopic, singleLeaderUser} from '../_requests'
import * as Yup from 'yup'
import axios from 'axios'
import {useFormik} from 'formik'
import Webcam from 'react-webcam'
import './webcamPopup.css'
import {UserContext} from '../../../../../../contextStore'
import {apiUrl} from '../../../../../../apiurl'

const API_URL = apiUrl
const IMAGE_UPLOAD = `${API_URL}/containers/topicimages/upload`
export function BusinessConnect() {
  const [searchLeaderListing, setsearchLeaderListing] = useState(true)
  const [singleLeaderData, setSingleLeaderData] = useState({name: '', id: ''})
  const [selectedLeaderId, setSelectedLeaderId] = useState(null)
  const {setMessage, dashBoardReload} = useContext(UserContext)
  const [capturedImage, setCapturedImage] = useState<string | null>(null)
  const [showWebcam, setShowWebcam] = useState(false)
  const [webcamAvailable, setWebcamAvailable] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState('')
  const [fileName, setFileName] = useState<any>('')
  const {userDetail} = useContext(UserContext)
  const webcamRef = useRef<any>(null)
  const closeButton = useRef<any>()
  const [uploading, setUploading] = useState<boolean>(false)
  const [selectedImageShow, setSelectedImageShow] = useState<any>()
  const [arrayOfFile, setArrayOfFile] = useState<any>([])
  const [pervFileUpload, setPervFileUpload] = useState('')
  const [errorMessage, setErrorMessage] = useState<any>(false)
  const FACING_MODE_USER = 'user'
  const FACING_MODE_ENVIRONMENT = 'environment'
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER)

  const handleLeaderSelect = async (leaderId: any) => {
    try {
      setSelectedLeaderId(leaderId)
      const singleLeaderResponseData = await singleLeaderUser(leaderId)
      setSingleLeaderData(singleLeaderResponseData ?? [])
    } catch (error) {
      console.error('Error fetching single leader data:', error)
    }
  }

  const handleImageChange = async (event: any) => {
    const file = event.target.files[0]
    if (file) {
      // setSelectedImage(file.name)
      if (pervFileUpload == 'file') {
        setArrayOfFile([])
      }
      const imageData = URL.createObjectURL(file)
      setSelectedImageShow(imageData)
      await uploadFile(file, 'image')
    } else {
      setSelectedImage('')
    }
  }

  const settings = {
    multiple: false,
    address: false,
    email: false,
    icon: false,
    name: true,
    tel: true,
  }

  const handlePickClick = useCallback(async () => {
    try {
      if ('contacts' in navigator && 'select' in navigator.contacts) {
        const selectedContacts = await navigator.contacts.select(['name', 'tel'], {
          multiple: settings.multiple,
        })

        if (selectedContacts.length > 0) {
          formik.setFieldValue('others.name', selectedContacts[0].name)
          formik.setFieldValue('others.cellNumber', selectedContacts[0].tel[0])
        }
      } else {
        setMessage('error', "Sorry, Your device doesn't support Contact Picker")
        setTimeout(() => setErrorMessage(''), 5000)
      }
    } catch (error: any) {
      setMessage('error', error?.message ? error?.message : 'Something Went Wrong')
    }
  }, [settings])

  const checkCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({video: true})
      setWebcamAvailable(true)
      stream.getTracks().forEach((track) => track.stop())
    } catch (error) {
      setWebcamAvailable(false)
    }
  }

  const uploadFile = async (file: File, typeOfFile: string) => {
    try {
      if (!file) {
        console.error('No file selected')
        return
      }

      const formData = new FormData()
      formData.append('file', file)

      const response = await axios.post(`${IMAGE_UPLOAD}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (typeOfFile === 'file') {
        setArrayOfFile([
          {
            name: response?.data?.result?.files?.file[0]?.name ?? '',
            image: response?.data?.result?.files?.file[0]?.name ?? '',
          },
        ])
        setPervFileUpload('file')
      }
      if (typeOfFile === 'image') {
        if (pervFileUpload === 'file') {
          setArrayOfFile([
            {
              name: response?.data?.result?.files?.file[0]?.name ?? '',
              image: response?.data?.result?.files?.file[0]?.name ?? '',
            },
          ])
        } else {
          if (arrayOfFile.length === 0) {
            setArrayOfFile([
              {
                name: response?.data?.result?.files?.file[0]?.name ?? '',
                image: response?.data?.result?.files?.file[0]?.name ?? '',
              },
            ])
          } else if (
            arrayOfFile.length === 1 ||
            arrayOfFile.length === 2 ||
            arrayOfFile.length === 3
          ) {
            setArrayOfFile([
              ...arrayOfFile,
              {
                name: response?.data?.result?.files?.file[0]?.name ?? '',
                image: response?.data?.result?.files?.file[0]?.name ?? '',
              },
            ])
          } else {
            setMessage('error', 'Only 4 images are allowed')
            return
          }
        }
        setPervFileUpload('image')
      }
    } catch (error) {
      setMessage('error', 'Something Went Wrong')
    }
  }

  const uploadImage = async (imageData: any) => {
    try {
      if (!imageData) {
        console.error('No image captured')
        return
      }
      // Create an Image element to load the captured image data
      const img = new Image()
      img.src = imageData

      // Wait for the image to load
      await new Promise((resolve) => (img.onload = resolve))

      // Create a canvas element
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      // Get the canvas context and draw the image on the canvas
      const ctx = canvas.getContext('2d')
      if (ctx) {
        ctx.drawImage(img, 0, 0) // Ensure ctx is not null before using it
      } else {
        //console.error('Canvas context is null')
        return
      }
      // Convert the canvas content to a data URL with 'image/jpeg' format and quality set to 1 (maximum quality)
      const jpgDataUrl = canvas.toDataURL('image/jpeg', 1)

      // Convert the data URL back to a Blob
      const byteCharacters = atob(jpgDataUrl.split(',')[1])
      const byteArrays = new Uint8Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays[i] = byteCharacters.charCodeAt(i)
      }
      const jpgBlob = new Blob([byteArrays], {type: 'image/jpeg'})

      // Create a FormData object to send the image file to the API
      const formData = new FormData()
      formData.append('image', jpgBlob, 'filename.jpg')

      // Set the loading state to true while uploading
      setUploading(true)

      // Make the API call to upload the image
      const response = await axios.post(`${IMAGE_UPLOAD}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      // Muti File Upload.
      if (pervFileUpload === 'file') {
        setArrayOfFile([
          {
            name: response?.data?.result?.files?.image[0]?.name ?? '',
            image: response?.data?.result?.files?.image[0]?.name ?? '',
          },
        ])
      } else {
        if (arrayOfFile.length === 0) {
          setArrayOfFile([
            {
              name: response?.data?.result?.files?.image[0]?.name ?? '',
              image: response?.data?.result?.files?.image[0]?.name ?? '',
            },
          ])
        } else if (
          arrayOfFile.length === 1 ||
          arrayOfFile.length === 2 ||
          arrayOfFile.length === 3
        ) {
          setArrayOfFile([
            ...arrayOfFile,
            {
              name: response?.data?.result?.files?.image[0]?.name ?? '',
              image: response?.data?.result?.files?.image[0]?.name ?? '',
            },
          ])
        } else {
          setMessage('error', 'Only 4 images are allowed')
          return
        }
      }

      // setFileName(response?.data?.result?.files?.image[0]?.name ?? "")
      let {filename} = response.data

      setUploading(false)
    } catch (error) {
      setMessage('error', 'Error uploading the image')
      setUploading(false)
    }
  }

  const toggleCameraFacingMode = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    )
  }

  const toggleWebcam = async () => {
    setShowWebcam((prev) => !prev)
    if (!webcamAvailable) {
      await checkCameraPermission()
      setShowWebcam(true)
    } else {
      setShowWebcam(true)
    }
  }

  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot()
      setCapturedImage(imageSrc)
      uploadImage(imageSrc)
    }
    setShowWebcam(false)
  }
  const handleCancelButtonClick = (index: any) => {
    const updatedArray = arrayOfFile.filter((item: any, i: any) => i !== index)
    setArrayOfFile(updatedArray)
  }
  const cancelWebcam = () => {
    // setCapturedImage(null);
    setShowWebcam(false)
  }

  const initialValue = {
    others: {
      name: '',
      companyName: '',
      cellNumber: '',
      agendaofMeeting: '',
    },
  }
  // validation
  const validationSchema = Yup.object().shape({
    others: Yup.object().shape({
      name: Yup.string()
        .required('Name is required')
        .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed in the name'),
      companyName: Yup.string()
        .required('Company name is required')
        .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed in the company name'),
      cellNumber: Yup.string()
        .required('Cell number is required')
        .matches(/^[6789]\d{9}$/, 'Invalid phone number'),
      agendaofMeeting: Yup.string()
        .required('Agenda of meeting is required')
        .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed in the agenda of meeting'),
    }),
  })

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: async (values, {resetForm}) => {
      try {
        if (!selectedLeaderId) {
          setErrorMessage(true)
          return
        } else {
          const postBusinessConnectlData = {
            topic_id: 21,
            user_id: userDetail.id,
            chapter_id: userDetail.chapter_id,
            others: {
              name: values.others.name,
              companyName: values.others.companyName,
              cellNumber: values.others.cellNumber,
              agendaofMeeting: values.others.agendaofMeeting,
            },
            descriptiontext: 'Business connect',
            isactive: 1,
            isdelete: 0,
            created_by: userDetail.id,
            business_connect_receiver: singleLeaderData.id,
            business_connect_sender: userDetail.id,
            postmedia: arrayOfFile,
          }

          await addUserTopic(postBusinessConnectlData)
          setMessage('success', 'Business Connect created successfully')
          setErrorMessage(false)
          // setsearchLeaderListing(true)
          setSelectedLeaderId(null)
          resetForm()
          setArrayOfFile([])
          setSingleLeaderData({name: '', id: ''})
          dashBoardReload()
        }
      } catch (error) {
        setMessage('error', 'Something went wrong')
      }
    },
  })

  return (
    <div
      className='modal fade ilead-default-modal'
      id='BusinessConnectModal'
      aria-hidden='true'
      aria-labelledby='BusinessConnectModalLabel'
    >
      <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
        <div className='modal-content'>
          <div className='modal-header py-5'>
            <h3 className='modal-title fs-3 fw-bold' id='BusinessConnectModalLabelToggleLabel'>
              Business Connect
            </h3>
            <button
              type='button'
              className='btn-close text-white'
              data-bs-target='#write_post_modal'
              data-bs-toggle='modal'
              data-bs-dismiss='modal'
              ref={closeButton}
              onClick={() => {
                setArrayOfFile([])
                formik.setFieldValue('others', {
                  name: '',
                  companyName: '',
                  cellNumber: '',
                  agendaofMeeting: '',
                })
                setSingleLeaderData({name: '', id: ''})
                setsearchLeaderListing(true)
                setSelectedLeaderId(null)
                setErrorMessage(false)
                formik.resetForm()
              }}
            ></button>
          </div>
          <div className='modal-body pt-7 write-post-wrapper'>
            {searchLeaderListing ? (
              <>
                <form
                  className='mb-7'
                  onSubmit={(e) => {
                    formik.handleSubmit(e)
                  }}
                >
                  <h3 className='text-black fw-bold fs-4 mb-6'>Business Connect For Leader</h3>
                  <Form.Group className='mb-4'>
                    <Form.Label
                      className='fs-14 border-bottom w-100 py-2 text-gray-700'
                      onClick={() => {
                        setsearchLeaderListing(false)
                      }}
                    >
                      {selectedLeaderId ? (
                        <b>{singleLeaderData.name}</b>
                      ) : (
                        <b className='required'>Select leader name</b>
                      )}
                    </Form.Label>
                    {!selectedLeaderId && errorMessage ? (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>This field is required</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Label className='w-100 py-2 text-gray-900 fw-bold'>
                      Whom you met?
                    </Form.Label>
                    <InputGroup className='mb-2'>
                      <Form.Control
                        placeholder='Name'
                        aria-label='name'
                        id='name'
                        maxLength={30}
                        className='border-right-0 ilead-input'
                        onChange={(e) => {
                          formik.setFieldValue('others.name', e.target.value)
                        }}
                        value={formik.values.others.name}
                      />

                      <InputGroup.Text
                        id='name'
                        className='bg-white ilead-input-group'
                        onClick={handlePickClick}
                      >
                        <i className='fa-solid fa-address-book' style={{fontSize: '18px'}}></i>
                      </InputGroup.Text>
                    </InputGroup>
                    {formik?.touched?.others?.name && formik?.errors?.others?.name && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik?.errors?.others?.name as any}</span>
                      </div>
                    )}
                    <Form.Control
                      type='text'
                      id='companyName'
                      className='ilead-input mb-2'
                      placeholder='Company Name'
                      maxLength={30}
                      onChange={(e) => {
                        formik.setFieldValue('others.companyName', e.target.value)
                      }}
                      value={formik.values.others.companyName}
                    />
                    {formik?.touched?.others?.companyName && formik?.errors?.others?.companyName && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik?.errors?.others?.companyName as any}</span>
                      </div>
                    )}
                    <Form.Control
                      type='text'
                      id='cellNumber'
                      className='ilead-input'
                      placeholder='Cell number'
                      maxLength={10}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        const key = e.key
                        const isNumeric = /^[0-9]$/.test(key)
                        const isSpecialKey =
                          key === 'Backspace' ||
                          key === 'Delete' ||
                          key === 'ArrowLeft' ||
                          key === 'ArrowRight' ||
                          key === 'ArrowUp' ||
                          key === 'ArrowDown' ||
                          (e.ctrlKey && key === 'c') ||
                          (e.ctrlKey && key === 'v') ||
                          (e.ctrlKey && key === 'x') ||
                          (e.ctrlKey && key === 'a')
                        if (!isNumeric && !isSpecialKey) {
                          e.preventDefault()
                        }
                      }}
                      onChange={(e) => {
                        formik.setFieldValue('others.cellNumber', e.target.value)
                      }}
                      value={formik.values.others.cellNumber}
                    />
                    {formik?.touched?.others?.cellNumber && formik?.errors?.others?.cellNumber && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik?.errors?.others?.cellNumber as any}</span>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Label className='w-100 py-2 text-gray-900 fw-bold'>
                      Write agenda of the meeting
                    </Form.Label>
                    <TextareaAutosize
                      minRows={5}
                      maxLength={200}
                      autoFocus
                      className='custom-text-area form-control fw-normal ilead-input'
                      placeholder='Enter agenda of the meeting'
                      onChange={(e) => {
                        formik.setFieldValue('others.agendaofMeeting', e.target.value)
                      }}
                      value={formik.values.others.agendaofMeeting}
                    />{' '}
                    {formik?.touched?.others?.agendaofMeeting &&
                      formik?.errors?.others?.agendaofMeeting && (
                        <div className='fv-plugins-message-container text-danger'>
                          <span role='alert'>{formik?.errors?.others?.agendaofMeeting as any}</span>
                        </div>
                      )}
                  </Form.Group>
                  <Form.Group className='mb-3 mt-7'>
                    <h5 className='mt-7 mb-7'>Upload photo of the meeting</h5>
                    <Button
                      variant='primary'
                      className='me-3 px-4 py-3'
                      onClick={() => toggleWebcam()}
                    >
                      <i className='bi bi-camera-fill pe-0' style={{fontSize: '20px'}}></i>
                    </Button>

                    {showWebcam && webcamAvailable ? (
                      <div className='webcam-popup'>
                        <div className='webcam-popup-content'>
                          <Webcam
                            audio={false}
                            ref={webcamRef}
                            videoConstraints={{
                              facingMode: facingMode,
                            }}
                          />
                          <button
                            type='button'
                            className='btn-close text-white cancel-button'
                            onClick={cancelWebcam}
                          ></button>
                          <div className='webcam-buttons'>
                            <Button
                              variant='primary'
                              className='me-3 px-4 py-3'
                              onClick={captureImage}
                            >
                              <i className='bi bi-camera-fill pe-0' style={{fontSize: '20px'}}></i>
                            </Button>

                            <Button
                              variant='primary'
                              className='me-3 px-4 py-3 toggle-button'
                              onClick={toggleCameraFacingMode}
                              style={{borderRadius: '30px'}}
                            >
                              <i className='bi bi-arrow-repeat pe-0' style={{fontSize: '20px'}}></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
                      <Form.Control
                        type='file'
                        accept='image/jpeg, image/png'
                        className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                        onChange={handleImageChange}
                      />
                      <i className='bi bi-image-fill pe-0' style={{fontSize: '20px'}}></i>
                    </div>
                  </Form.Group>
                  <div className='row'>
                    {arrayOfFile.map((item: any, i: any) => {
                      let itemLast = item.image.split('.').pop()

                      if (
                        itemLast == 'jpeg' ||
                        itemLast == 'jpeg' ||
                        itemLast == 'png' ||
                        itemLast == 'jpg'
                      ) {
                        return (
                          <div className='col'>
                            <div
                              className='white-close-image'
                              style={{position: 'relative', width: '130px'}}
                            >
                              <img
                                src={`${API_URL}/containers/topicimages/download/${item.image}`}
                                alt='Captured'
                                style={{width: 130, height: 90}}
                              />
                              <button
                                type='button'
                                className='btn-close text-white fs-4'
                                style={{
                                  backgroundColor: 'red',
                                  position: 'absolute',
                                  right: '5px',
                                  top: '5px',
                                }}
                                onClick={() => handleCancelButtonClick(i)}
                              />
                            </div>
                          </div>
                        )
                      }
                    })}
                  </div>
                  <Form.Group className='mt-5 d-flex'>
                    <Button
                      variant='secondary'
                      type='button'
                      className='w-50 me-3'
                      data-bs-target='#write_post_modal'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                      onClick={() => {
                        setArrayOfFile([])
                        formik.setFieldValue('others', {
                          name: '',
                          companyName: '',
                          cellNumber: '',
                          agendaofMeeting: '',
                        })
                        setSingleLeaderData({name: '', id: ''})
                        setsearchLeaderListing(true)
                        setSelectedLeaderId(null)
                        setErrorMessage(false)
                        formik.resetForm()
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='primary'
                      type='submit'
                      className='w-50'
                      data-bs-toggle={formik.isValid ? 'modal' : undefined}
                      data-bs-dismiss={formik.isValid ? 'modal' : undefined}
                      disabled={
                        formik.isSubmitting ||
                        !selectedLeaderId ||
                        !formik.values.others.agendaofMeeting ||
                        !formik.values.others.cellNumber ||
                        !formik.values.others.companyName ||
                        !formik.values.others.name
                      }
                      onClick={() => {
                        if (!selectedLeaderId) {
                          setErrorMessage(true)
                        }
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Group>
                </form>
              </>
            ) : (
              <SearchLeaderListing
                onClick={setsearchLeaderListing}
                onSelectLeader={handleLeaderSelect}
              ></SearchLeaderListing>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
