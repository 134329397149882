/* eslint-disable react/jsx-no-target-blank */
import React, {useState, useRef, useContext, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {Button, Image, Dropdown, Form, Modal} from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../../../helpers'
import {Link, useNavigate} from 'react-router-dom'
import {useLayout} from '../../../core'
import {logoutApi, getChapterList} from '../../../../../app/modules/auth/core/_requests'
import {UserContext} from '../../../../../contextStore'
import {redirectAdminURL, apiUrl} from '../../../../../apiurl'
import {accessUser} from '../../../../../utils/privileges'
import axios from 'axios'
import {useAuth} from '../../../../../app/modules/auth'
import {checkDisplayValue} from '../../../../../utils'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
var pjson = require('../../../../../../package.json')

const API_URL = redirectAdminURL
const API_URL_PROFILE = apiUrl

const SidebarMenuMain = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {setMessage, removeAuth, chapterID} = useContext(UserContext)
  const {userDetail, token, setChapterID, dashBoardReload} = useContext(UserContext)
  const [isShowUserManagement, setIsShowUserManagement] = useState(false)
  const [isShowSessionAttendance, setIsShowSessionAttendance] = useState(false)
  const [isShowVisitorPanel, setIsShowVisitorPanel] = useState(false)
  const [isShowSessionSummary, setIsShowSessionSummary] = useState(false)
  const [isShowChapterDropdown, setIsShowChapterDropdown] = useState(false)
  const [chapterList, setChapterList] = useState([])
  const [selectedChapter, setSelectedChapter] = useState<any>('')
  const userid = userDetail?.id ?? ''
  const userChaperid = userDetail?.chapter_id ?? ''
  const userProfileImageName = userDetail?.profile
  const Roleid = userDetail?.role_id ?? ''

  const userManagement_URL = `${API_URL}/usermanagement?token=${token}&userid=${userid}&mapikey=hello`
  const libraryManagement_URL = `${API_URL}/home/librarymanagement?id=${token}`
  const visitorPanel_URL = `${API_URL}/visitor_panel?token=${token}&userid=${userid}&mapikey=hello`
  const sessionAttendance_URL = `${API_URL}/session-attendance?token=${token}&userid=${userid}&mapikey=hello`
  const monthlyReport_URL = `${API_URL}/home/monthlypoints?id=${token}`
  const sessionSummary_URL = `${API_URL}/home/stat?id=${token}`
  const userManagementUsers = accessUser.usermanagement
  const sessionAttendanceUsers = accessUser.sessionattenance
  const visitorpanleUsers = accessUser.visitorpanel
  const sessionSumaryUser = accessUser.sessionsummary
  const chapterDropdownUser = accessUser.chapterdropdown
  const [GIcolor, setGIcolor] = useState<string | null>('')
  const user_data_URL = `${API_URL_PROFILE}/users/${userid}`
  const user_profile_URL = `${API_URL_PROFILE}/users/userprofile`
  const VERTICAL_PERMISSION_URL = `${API_URL_PROFILE}/verticalspermissions/userPermission`
  const [logoutPop, setLogoutPop] = useState(false)
  const [userProfileData, setUserProfileData] = useState<any>([])
  const [data, setData] = useState<any>({})

  const getMobileView = () => {
    return !(Math.min(window.screen.width, window.screen.height) < 600)
  }

  const showMonthlyReport = () => {
    return (userDetail?.iscaptain == 1 || userDetail?.iscaptain == true || accessUser.sessionsummary.find((x: any) => x == userid))
  }

  useEffect(() => {}, [])
  useEffect(() => {
    if (userManagementUsers.includes(userid)) {
      setIsShowUserManagement(true)
    }
    if (sessionAttendanceUsers.includes(userid)) {
      setIsShowSessionAttendance(true)
    }
    if (visitorpanleUsers.includes(userid)) {
      setIsShowVisitorPanel(true)
    }
    if (sessionSumaryUser.includes(userid)) {
      setIsShowSessionSummary(true)
    }
    if (chapterDropdownUser.includes(userid)) {
      setIsShowChapterDropdown(true)
    }
    fetchchapterList()
    setSelectedChapter(userChaperid ?? '')
  }, [])

  const fetchVerticalData = async () => {
    try {
      const res = await axios.get(VERTICAL_PERMISSION_URL)
      setData(res?.data)
      //console.log('userPermission=>', data)
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const fetchchapterList = async () => {
    try {
      const res = await getChapterList()
      setChapterList(res?.data)
    } catch (error) {}
  }
  useEffect(() => {
    fetchVerticalData()
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const res = await axios.get(user_data_URL)
      if (res?.data?.GIColor) {
        setGIcolor(res?.data?.GIColor)
      }

      const response = await axios.get(user_profile_URL)
      if (response?.data) {
        setUserProfileData(response?.data)
      }
    } catch (error) {
      console.error('Error -->:', error)
    }
  }

  const logout = () => {
    try {
      logoutApi(token)
      setMessage('success', 'Logout Successfully')
      localStorage.clear()
      removeAuth()
      navigate('/login')
    } catch (err) {
      setMessage('success', 'Logout Successfully')
      localStorage.clear()
      removeAuth()
      navigate('/login')
    }
  }
  const {config} = useLayout()
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  const {currentUser} = useAuth()
  return (
    <>
      <div className='user-menu mb-lg-3 mt-lg-3 px-xl-4 py-lg-5 pt-0'>
        <Link to='/dashboard' className='d-none d-md-block text-center header-brand py-3'>
          {config.layoutType === 'dark-sidebar' ? (
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/ilead-logo-white.png')}
              className='h-45px app-sidebar-logo-default full-logo'
            />
          ) : (
            <>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/ilead-logo-white.png')}
                className='h-45px app-sidebar-logo-default theme-light-show full-logo'
              />
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/ilead-logo-white.png')}
                className='h-45px app-sidebar-logo-default theme-dark-show full-logo'
              />
            </>
          )}
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/ilead-logo-white-small.png')}
            className='h-40px app-sidebar-logo-minimize small-logo'
          />
        </Link>
        <div className='bg-ilead mb-4 header-brand d-flex py-5 ps-4'>
          <div className='d-flex justify-content-between w-100 align-items-center'>
            <div
              className='symbol symbo-50'
              style={{
                borderColor: GIcolor ? GIcolor : '',
                borderStyle: GIcolor ? 'solid' : '',
              }}
            >
              <Image
                src={
                  userDetail.profile
                    ? `${API_URL_PROFILE}/containers/userprofile/download/${userProfileImageName}`
                    : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                }
                onClick={(e) => {
                  navigate('/leader-profile')
                }}
                alt=''
                onError={({currentTarget}: any) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                }}
              />
            </div>
            <div className='user-details ps-4 flex-1 text-truncate'>
              <h4
                className='text-white text-hover-primary fs-6 fw-bold mb-0'
                onClick={(e) => {
                  navigate('/leader-profile')
                }}
              >
                {userDetail?.name}
              </h4>
              <span className='text-white fs-13 d-block text-truncate'>
                {' '}
                {userDetail?.companyname}
              </span>
              <span className='text-white fs-13 d-block'>
                Chapter : {checkDisplayValue(userProfileData?.chapters?.name)} -{' '}
                {checkDisplayValue(userProfileData?.group?.name)}
              </span>
              <span className='text-white fs-13 d-block'>
                Vertical : {checkDisplayValue(data?.vertical_name)}
              </span>
            </div>
            {/* <div className='info-icon'>
              <Button
                variant='link'
                className='text-end text-white'
                data-bs-target='#ActivityPointsModal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
              >
                <i className='fa-solid fa-circle-info text-white'></i>
              </Button>
            </div> */}
            <a
              href='#'
              className='btn btn-icon btn-sm btn-active-color-primary mt-n2'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-start'
              data-kt-menu-overflow='false'
            >
              <i className='bi bi-gear text-muted fs-3'></i>

              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
                data-kt-menu='true'
                data-popper-placement='bottom-start'
              >
                <div className='menu-item px-5'>
                  <a
                    className='menu-link px-5 text-dark'
                    data-bs-target='#ActivityPointsModal'
                    data-bs-toggle='modal'
                    data-bs-dismiss='modal'
                  >
                    Activity Points
                  </a>
                  <Link to={'/leader-profile'} className='menu-link px-5 text-dark'>
                    My Profile
                  </Link>
                </div>
                <div className='menu-item px-5'>
                  <a
                    onClick={() => {
                      setLogoutPop(true)
                    }}
                    className='menu-link px-5 text-dark'
                  >
                    Sign Out
                  </a>
                </div>
              </div>
            </a>
          </div>
        </div>
        <Modal
          show={logoutPop}
          onHide={() => setLogoutPop(false)}
          dialogClassName='modal-90w'
          aria-labelledby='example-custom-modal-styling-title'
        >
          <Modal.Header closeButton>
            <Modal.Title id='example-custom-modal-styling-title'>Sign Out</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to Sign Out ?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='primary'
              onClick={() => {
                logout()
              }}
            >
              Confirm
            </Button>
            <Button
              variant='secondary'
              onClick={() => {
                setLogoutPop(false)
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {isShowChapterDropdown && (
          <div className='admin-menu select_hide py-3'>
            <Form.Select
              className='p-2 text-white'
              style={{
                backgroundColor: 'rgba(255,255,255,0.2)',
                border: '1px solid rgba(255,255,255,0.4)',
                borderRadius: '4px',
              }}
              aria-label='Default select example'
              onChange={(event: any) => {
                // setSelectedChapter(item?.id)
                setChapterID(event.target.value)
                dashBoardReload()
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                  // setIsLoading(false);
                }, 1000)
              }}
            >
              {chapterList &&
                chapterList.map((item: any, index: any) => {
                  return (
                    <option selected={chapterID == item?.id} value={item?.id}>
                      {item?.name}
                    </option>
                  )
                })}
            </Form.Select>
          </div>
        )}
        <SidebarMenuItem
          to='/dashboard'
          icon='home-2'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        />
        {isShowVisitorPanel ? (
          <SidebarMenuItem to='/visitors-list' icon='send' title='Visitor Invitation' />
        ) : (
          <>
            {data?.invition_history == 1 ? (
              <>
                <SidebarMenuItem to='/invitation-history' icon='send' title='Invitation History' />
              </>
            ) : null}
          </>
        )}
        {getMobileView() ? (
          <>
          <SidebarMenuItem to='/directory' icon='profile-user' title='Directory' />{' '}
        </>
        ) : (
          ''
        )}
        <SidebarMenuItem to='/leaders' icon='magnifier' title='Search Leader' />
        {/* <SidebarMenuItem to='/visitors-list' icon='send' title='Visitor Invitation' /> */}
        <SidebarMenuItem to='/library' icon='book-open' title='Library' />
        <SidebarMenuItem to='/iciwf-uupl' icon='award' title='ICIWF/UUPL' />
        <SidebarMenuItem to='/terms-conditions' icon='document' title='Terms & Conditions' />
        <SidebarMenuItem to='/feedback' icon='messages' title='Feedback / Query' />
        {accessUser.sessionsummary.find((x: any) => x == userid) ? (
          <>
            <SidebarMenuItem to='/summery-session' icon='file' title='Session Summary' />
          </>
        ) : (
          ''
        )}

        {showMonthlyReport() ? <SidebarMenuItem to='/monthly-report' icon='chart-pie-3' title='Monthly Report' /> : ""}


        {accessUser.sessionattenance.find((x: any) => x == userid) ? (
          <>
            <SidebarMenuItem to='/session-attendance' icon='time' title='Session Attendance' />
          </>
        ) : (
          ''
        )}
        {accessUser.paneladmin.includes(userid) || accessUser.panelsuperadmin.includes(userid) ? (
          <>
            {' '}
            <SidebarMenuItem to='/visitor-panel' icon='star' title='Visitor Panel' />{' '}
          </>
        ) : (
          ''
        )}{' '}
        {accessUser.sessionfeedback.find((x: any) => x == userid) ? (
          <>
            <SidebarMenuItem to='/session-feedback' icon='notepad' title='Session Feedback' />
          </>
        ) : (
          ''
        )}
        {accessUser.groupmeetingreport.find((x: any) => x == userid) ? (
          <>
            <SidebarMenuItem
              to='/group-meeting-report'
              icon='document'
              title='Group Meeting Reports'
            />
          </>
        ) : (
          ''
        )}
        {accessUser.usermanagement.find((x: any) => x == userid) ? (
          <>
            <SidebarMenuItem to='/leader-board' icon='notepad' title='Leader Board' />
            <SidebarMenuItem to='/event-management' icon='profile-user' title='Event Management' />

            <SidebarMenuItemWithSub
              to='/#'
              title='Group Management'
              fontIcon='bi-chat-left'
              icon='profile-user'
            >
              <SidebarMenuItem
                to='/group-management'
                title='Group Management Listing'
                icon=''
              ></SidebarMenuItem>

              <SidebarMenuItem
                to='/chat-group-management'
                title='Chat Group Management'
                icon=''
              ></SidebarMenuItem>
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub
              to='/#'
              title='Message Center'
              fontIcon='bi-chat-left'
              icon='messages'
            >
              <SidebarMenuItem
                to='/message-center-reminders'
                title='Reminders'
                icon=''
              ></SidebarMenuItem>
              <SidebarMenuItem
                to='/message-center-notifications'
                title='Notifications'
                icon=''
              ></SidebarMenuItem>
              <SidebarMenuItem
                to='/message-center-template'
                title='Templates'
                icon=''
              ></SidebarMenuItem>
            </SidebarMenuItemWithSub>
            <SidebarMenuItem to='/chapters' title='Chapters' icon='notepad' />
            <SidebarMenuItem to='/vertical' icon='notepad' title='Verticals' />
            {/* <SidebarMenuItem to='/group-management' icon='profile-user' title='Group Management' /> */}
            <SidebarMenuItem to='/users-list' icon='profile-user' title='User Management' />
            <SidebarMenuItem
              to='/libraries-management'
              icon='book-open'
              title='Library Management'
            />
          </>
        ) : (
          ''
        )}
        {/* <SidebarMenuItem to='/leader-profile' icon='profile-circle' title='My Profile' /> */}
        {getMobileView() ? (
          <>
            {data?.tyca_presentation == 1 ? (
              <SidebarMenuItem to='/tyca' icon='questionnaire-tablet' title='TYCA Presentations' />
            ) : null}
            <SidebarMenuItem to='/index' icon='chart-line-up-2' title='Index' />
          </>
        ) : (
          ''
        )}
        {/* <SidebarMenuItem
          to='#'
          icon='exit-left'
          title='Sign Out'
          action='button'
          logout={() => {
            logout()
          }}
        /> 
      </div>
      {!isShowUserManagement &&
        !isShowSessionAttendance &&
        !isShowSessionSummary &&
        !isShowVisitorPanel ? (
        ''
      ) : (
        <div className='admin-menu px-xl-3 py-lg-5'>
          <h5 className='text-white ps-7 mt-5 mt-md-0'>Administrator</h5>
          {isShowSessionSummary ? (
            <SidebarMenuItem
              to={sessionSummary_URL}
              icon='questionnaire-tablet'
              title='Session Summary'
              redirect={true}
            />
          ) : (
            ''
          )}
          {isShowSessionSummary ? (
            <SidebarMenuItem
              to={monthlyReport_URL}
              icon='graph-up'
              title='Monthly Report'
              redirect={true}
            />
          ) : (
            ''
          )}
          {isShowSessionAttendance ? (
            <SidebarMenuItem
              to={sessionAttendance_URL}
              icon='people'
              title='Session Attendance'
              redirect={true}
            />
          ) : (
            ''
          )}
          {isShowVisitorPanel ? (
            <SidebarMenuItem
              to={visitorPanel_URL}
              icon='user-tick'
              title='Visitor Panel'
              redirect={true}
            />
          ) : (
            ''
          )}
          {isShowSessionSummary ? (
            <SidebarMenuItem
              to={libraryManagement_URL}
              icon='book'
              title='Library Management'
              redirect={true}
            />
          ) : (
            ''
          )}
          {isShowUserManagement ? (
            <SidebarMenuItem
              to={userManagement_URL}
              icon='profile-user'
              title='User Management'
              redirect={true}
            />
          ) : (
            ''
          )}
          {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' /> */}
      </div>
      <div className='admin-menu px-xl-3 py-lg-5 mt-3'>
        <div className='menu-item'>
          <div
            className='menu-link'
            style={{color: 'white', fontWeight: 'bold', fontStyle: 'italic'}}
          >
            V{pjson.version}
          </div>
        </div>
      </div>
    </>
  )
}
export {SidebarMenuMain}
