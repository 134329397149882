import { Suspense, useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'
import * as actions from '../store/action'
import { COMETCHAT_CONSTANTS } from '../consts'
import { connect } from 'react-redux'
import { UserContext } from '../contextStore'
import axios from 'axios'
import { apiUrl } from '../apiurl'
import { messaging, firebase } from '../firebase';
const updateDeviceTokenURL = `${apiUrl}/users/updateDeviceToken`

const App = (props: any) => {
  const { isLoggedIn, userDetail, setAuthAccessUser,setChapterID } = useContext(UserContext)

  const updateDeviceToken = async (data:any) => {
    try{
      await axios.post(updateDeviceTokenURL, data);
    }
    catch(err:any){
      console.log("firebase error",err)
    }
  }

  const getMobileOS = () => {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      return "Android"
    }
    else if (/iPad|iPhone|iPod/.test(ua)|| (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)){
      return "iOS"
    }
    return "Other"
  }

  const getAppleDevice = () => {
    let userAgent = navigator.userAgent;
    if (userAgent.indexOf('Mac OS X') !== -1) {
      return true
    } else if (userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1) {
      return true
    }
    else{
      return false
    }
  }

  //console.log("userDetailuserDetail",userDetail)

  //console.log("getMobileOS",getMobileOS())

  const requestNotificationPermissionForAndroid = async () => {
    try {
      if (firebase.messaging.isSupported()) {
        await Notification.requestPermission();
        const token = await messaging?.getToken() as any
        localStorage.setItem('devicetoken',token)
        const id = userDetail?.id ?? null
        if(id && (getMobileOS())){
          await updateDeviceToken({data:{
            id: id,
            devicetoken: token,
            osid: (getMobileOS() === "Android" || getMobileOS() === "Other") ? 1 : 2
          }})
        }
      }
    } catch (error) {
      console.error('Error getting notification permission:', error);
    }
  };

  const requestNotificationPermissionForApple = async () => {
    try {
      if ('Notification' in window) { 
        const id = userDetail?.id ?? null
        let subs_id = localStorage.getItem('ios_subs_id')
        if(id){
          setTimeout(()=>{
            updateDeviceToken({data:{
              id: id,
              devicetoken: subs_id,
              osid: 2
            }})
          },5000)
        }
      }
    } catch (error) {
      console.error('Error getting notification permission:', error);
    }
  };
  

  useEffect(()=>{
    // Call this function to request permission.
    if(getAppleDevice()){
      // alert("getAppleDevice() return true")
      requestNotificationPermissionForApple()
    }else{
      // alert("getAppleDevice() return false")
      requestNotificationPermissionForAndroid();  
    }  
  },[])


  // On initization set the isOnline state.
  useEffect(() => {
    if (isLoggedIn && userDetail?.id && userDetail?.name) {
      let id = userDetail.id.toString() ?? false
      let name = userDetail?.name ?? false
      props.onLogin(id, COMETCHAT_CONSTANTS.AUTH_KEY, name)
      props.getLoggedinUser()
    }
    // dispatch(actions.auth)
  }, [isLoggedIn])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

const mapStateToProps = (state: any) => {
  return {
    isLoggedIn: state.isLoggedIn,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getLoggedinUser: () => dispatch(actions.authCheckState()),
    onLogin: (uid: any, authKey: any) =>
      dispatch(actions.auth(uid, authKey)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
