import { useContext, useEffect, useRef, useState } from "react";
import Heart from "react-animated-heart";
import { Button, Carousel, Dropdown, Form, Image, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { UserContext } from "../../../../../contextStore";
import { Link, useNavigate } from "react-router-dom";
import { apiUrl } from '../../../../../apiurl'
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { checkDisplayValue } from "../../../../../utils";
import moment from "moment";
import SVG from 'react-inlinesvg'
import * as Yup from 'yup'

import { useReward } from 'react-rewards'
import { useFormik } from 'formik'

import { RWebShare } from 'react-web-share'
import { createCommentApi, deletePostApi, getCommentApi, likePostApi, removeBookmarkApi, setBookmarkApi, showHidePostApi, deleteCommentApi } from "./_requests";
const API_URL = apiUrl
const useInterval = (callback: () => void) => {
    const callbackRef = useRef(() => { })

    useEffect(() => {
        callbackRef.current = callback
    }, [callback])

    useEffect(() => {
        const timerId = setInterval(() => callbackRef.current(), 100)
        return () => clearInterval(timerId)
    }, [])
}

const AnimationStyle = {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    zIndex: '1',
}


export default function ShowPinnedPostModal({ show, handleClose, data, index, pinnedData, setPinnedData }: any) {

    const { setMessage, userDetail, reload } = useContext(UserContext)
    const [userId, setUserId] = useState<any>('')
    const [likeList, setLikeList] = useState<any>('')
    const [showCommentModal, setShowCommentModal] = useState<any>(false)
    const [showLikeModal, setShowLikeModal] = useState<any>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<any>(false)
    const [commentData, setCommentData] = useState<any>([])
    const deleteModelPostRef = useRef<any>()
    const [deleteModel, setdeleteShowModel] = useState<boolean>(false)

    const navigate = useNavigate()
    const [loading, setLoading] = useState<any>({
        send: false,
        list: false,
        hide: false,
        delete: false,
        like: false,
    })
    useEffect(() => {
        setUserId(userDetail?.id ?? '')
    }, [userDetail])
    const isLiked = () => {
        let tempArray = data?.usertopiclikes?.filter((x: any) => x.user_id == userId) ?? []
        return tempArray?.length > 0 ? true : false
    }
    useEffect(() => {
        setUserId(userDetail?.id ?? '')
    }, [userDetail])


    const extractVideoID = (url: string) => {
        const urlParams = new URLSearchParams(new URL(url).search)
        return urlParams.get('v')
    }

    const initialValues = {
        chattext: '',
        thank_you: 0,
        comment: 0,
        isactive: 1,
        isdelete: 0,
        created_by: '1',
        user_topic_id: null,
        user_id: '1',
    }


    const validationSchema = Yup.object().shape({
        chattext: Yup.string().required('Comment is required').max(250, 'Comments not more than 250 characters'),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setLoading(Object.assign({}, loading, { send: true }))
            try {
                values.user_id = userId
                values.user_topic_id = data.id
                // Make the API call
                await createCommentApi(values)
                getPostComment()
                formik.resetForm()
                setMessage('success', 'Commented successfully')
                let newData = pinnedData
                newData[index].totalcomments = newData[index].totalcomments + 1
                setPinnedData([...newData])
                setLoading(Object.assign({}, loading, { send: false }))
            } catch (error) {
                console.error(error)

                setMessage('error', 'Error creating the announcement')
                setLoading(Object.assign({}, loading, { send: false }))
            }
        },
    })

    const { reward: rewardLeft, isAnimating: isAnimatingLeft } = useReward(
        `rewardLeft-${data.id}`,
        `${data.topic_id == 15 ? 'balloons' : 'confetti'}`,
        {
            angle: 45,
            position: 'absolute',
            spread: 45,
            decay: 0.96,
            startVelocity: 50,
            // elementCount: 105,
            // elementSize: 7,
        }
    )
    const { reward: rewardRight, isAnimating: isAnimatingRight } = useReward(
        `rewardRight-${data.id}`,
        `${data.topic_id == 15 ? 'balloons' : 'confetti'}`,
        {
            angle: 135,
            position: 'absolute',
            spread: 45,
            decay: 0.96,
            startVelocity: 50,
            // elementCount: 105,
            // elementSize: 7,
        }
    )
    useEffect(() => {
        const leftElement = document.getElementById(`rewardLeft-${data?.id}`);
        const rightElement = document.getElementById(`rewardRight-${data?.id}`);
        
        const timerId = setTimeout(() => {
        if (!isAnimatingRight && !isAnimatingLeft && leftElement && rightElement) {
          rewardLeft();
          rewardRight();
          
        }
      }, 500); 
      return () => clearTimeout(timerId);
      }, [isAnimatingRight, isAnimatingLeft, data?.id]);
    
    const setBookMark = () => {
        try {

            let newData = pinnedData
            newData[index].isbookmarked = true
            let reqData = newData[index]
            setBookmarkApi({ user_topic_id: reqData?.id, leader_id: userDetail?.id })
            setPinnedData([...newData])
        } catch (err) {
            console.error('error', err)
        }
    }

    const removeBookMark = () => {
        try {
            let newData = pinnedData
            newData[index].isbookmarked = false
            let reqData = newData[index]
            removeBookmarkApi({ user_topic_id: reqData?.id, leader_id: userDetail?.id })
            setPinnedData([...newData])
        } catch (err) {
            console.error('error', err)
        }
    }

    const showHideAction = async () => {
        setLoading(Object.assign({}, loading, { hide: true }))
        try {
            await showHidePostApi({ user_topic_id: data?.id })
            let newData = pinnedData
            newData[index].isactive = data?.isactive ? 0 : 1
            setPinnedData([...newData])
            setLoading(Object.assign({}, loading, { hide: false }))
        } catch (err) {
            console.error(err)
            setLoading(Object.assign({}, loading, { hide: false }))
        }
    }
    const rediecttoLeaderProfile = (id: any) => {
        navigate(`/leaders/${id}`)
    }

    const deletePostAction = async () => {
        setLoading(Object.assign({}, loading, { delete: true }))
        try {
            let responseData = await deletePostApi(data?.id)
            if (responseData.count == 1) {
                let newData = pinnedData.filter((x: any, id: any) => id != index)
                setPinnedData([...newData])
                setMessage('success', 'Delete Successfully')
                deleteModelPostRef?.current?.click()
                setdeleteShowModel(false)
                handleClose()
            }
            setLoading(Object.assign({}, loading, { delete: false }))
        } catch (err) {
            console.error(err)
            setLoading(Object.assign({}, loading, { delete: false }))
        }
    }

    const getIcaniwillLeaderList = async () => {
        setLoading(Object.assign({}, loading, { list: true }))
        try {
            let responseData = await getCommentApi(data.uuid)
            let likeData = responseData?.usertopiclikes ?? []
            await setLikeList([...likeData])
            setLoading(Object.assign({}, loading, { list: false }))
        } catch (err) {
            console.error(err)
            setLoading(Object.assign({}, loading, { list: false }))
        }
    }
    const getPostComment = async () => {
        setLoading(Object.assign({}, loading, { list: true }))
        try {
            let responseData = await getCommentApi(data?.uuid)

            let cData = responseData?.usertopicchats ?? []
            await setCommentData([...cData])
            let newData = pinnedData
            newData[index].totalcomments = cData.length
            setPinnedData([...newData])
            setLoading(Object.assign({}, loading, { list: false }))
        } catch (err) {
            console.error(err)
            setLoading(Object.assign({}, loading, { list: false }))
        }
    }

    const likePostAction = async () => {
        setLoading(Object.assign({}, loading, { like: true }))
        try {
            let responseData = await likePostApi({
                user_topic_id: data.id,
                user_id: userId,
            })
            let newData = pinnedData
            newData[index].usertopiclikes = responseData.userTopicLikeFinal
            newData[index].totallikes = responseData.totallikes
            setPinnedData([...newData])
            setLoading(Object.assign({}, loading, { like: false }))
        } catch (err) {
            console.error(err)
            setLoading(Object.assign({}, loading, { like: false }))
        }
    }

    const deleteCommentAction = async (deleteId: any) => {
        try {
          if(deleteId){
            let responseData = await deleteCommentApi(deleteId)
            if (responseData.count == 1) {
              getPostComment()
              setMessage('success', 'Delete Successfully')
            }
          }else{
            setMessage('error', 'Something Went Wrong')
          }
        } catch (err) {
          console.error(err)
          setMessage('error', 'Something Went Wrong')
        }
    }

    const icaniwillLeaderList = () => {
        return likeList && likeList.length > 0 ? (
            likeList.map((icaniwll: any, index: any) => {
                return (
                    <div className='single_comment_view d-flex align-items-start mb-3' key={index}>
                        <Link
                            to={`/leaders/${icaniwll?.user?.id}`}
                            onClick={() => {
                                rediecttoLeaderProfile(icaniwll?.user?.id)
                            }}
                            data-bs-dismiss='modal'
                            aria-label='Close'
                        >
                            <div className='symbol symbol-40px me-3'>
                                <Image
                                    src={
                                        icaniwll?.user?.profile
                                            ? `${API_URL}/containers/userprofile/download/${icaniwll?.user?.profile}`
                                            : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                    }
                                    onError={({ currentTarget }: any) => {
                                        currentTarget.onerror = null // prevents looping
                                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                    }}
                                ></Image>
                            </div>
                        </Link>
                        <Link
                            to={`/leaders/${icaniwll?.user?.id}`}
                            onClick={() => {
                                rediecttoLeaderProfile(icaniwll?.user?.id)
                            }}
                            data-bs-dismiss='modal'
                            aria-label='Close'
                        >
                            <div className='flex-grow-1 d-flex flex-column commnet_box_right'>
                                <div className='commented_user_details mb-1'>
                                    <Link to='' className='ilead-text text-hover-primary fs-6 fs-md-5 fw-bold me-2'>
                                        {icaniwll.user.name}
                                    </Link>
                                    <span className='round-separator me-2'></span>
                                    <span className='text-gray-600'>{moment(icaniwll.created_at).fromNow()}</span>
                                </div>
                                {/* <div className='commnet_content text-black'> */}
                                {/* {itemMessage.chat} */}
                                {/* </div> */}
                            </div>
                        </Link>
                    </div>
                )
            })
        ) : (
            <div className='single_comment_view d-flex align-items-start'>
                <div className='empty-comments text-center w-100'>
                    {/* <Image
               fluid
               src={toAbsoluteUrl('/media/ilead/empty-comments.png')}
               className='w-150px mb-4'
             ></Image>
             <h4 className='text-gray-900'>No Comments Yet</h4> */}
                    <span className='text-gray-500'>No "I CAN I WILL" by any Leader Yet</span>
                </div>
            </div>
        )
    }

    const handleToshow = (file: any) => {
        if (file) {
            const url = `${API_URL}/containers/topicimages/download/${file}`
            window.open(url, '_blank')
        }
    }
    // link convert data
    const convertTextToLinks = (text: any) => {
        const urlRegex = /(https?:\/\/[^\s]+|www[^\s]+)/
        const parts = text.split(urlRegex)

        return parts.map((part: any, index: any) => {
            if (part.match(urlRegex)) {
                let url = part

                if (!part.startsWith('https://www.youtube.com')) {
                    if (url.startsWith('www.')) {
                        url = 'http://' + part
                    }
                    return (
                        <a key={index} href={url} target='_blank' rel='noopener noreferrer'>
                            {part}
                        </a>
                    )
                } else {
                    const videoID = extractVideoID(part)
                    if (videoID) {
                        const embedURL = `//www.youtube.com/embed/${videoID}`
                        return (
                            <div key={index}>
                                <a href={part} target='_blank' rel='noopener noreferrer'>
                                    {part}
                                </a>
                                <iframe
                                    title='video'
                                    width='450'
                                    height='250'
                                    src={embedURL}
                                    frameBorder='0'
                                    allowFullScreen
                                ></iframe>
                            </div>
                        )
                    }
                }
            } else {
                return <span key={index}>{part}</span>
            }
        })
    }

    // List for comment for single post
    const commentList = () => {
        return commentData && commentData?.length > 0 ? (
            commentData?.map((itemMessage: any, index: any) => {
                return (
                    <div className='single_comment_view d-flex align-items-start mb-3' key={index}>
                        <Link
                            to={`/leaders/${itemMessage?.user?.id}`}
                            onClick={() => {
                                rediecttoLeaderProfile(itemMessage?.user?.id)
                            }}
                            data-bs-dismiss='modal'
                            aria-label='Close'
                        >
                            <div className='symbol symbol-40px me-3'>
                                <Image
                                    src={
                                        itemMessage?.user?.profile
                                            ? `${API_URL}/containers/userprofile/download/${itemMessage?.user?.profile}`
                                            : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                    }
                                    onError={({ currentTarget }: any) => {
                                        currentTarget.onerror = null // prevents looping
                                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                    }}
                                ></Image>
                            </div>
                        </Link>
                        <Link
                            to={`/leaders/${itemMessage?.user?.id}`}
                            onClick={() => {
                                rediecttoLeaderProfile(itemMessage?.user?.id)
                            }}
                            data-bs-dismiss='modal'
                            aria-label='Close'
                        >
                            <div className='flex-grow-1 d-flex flex-column commnet_box_right'>
                                <div className='commented_user_details mb-1'>
                                    <Link to='' className='ilead-text text-hover-primary fs-6 fs-md-5 fw-bold me-2'>
                                        {itemMessage.user.name}
                                    </Link>
                                    <span className='round-separator me-2'></span>
                                    <span className='text-gray-600'>{moment(itemMessage.created_at).fromNow()}</span>
                                </div>
                                <div className='commnet_content modal-wrap-text text-black'>{itemMessage.chat}</div>
                            </div>
                        </Link>
                        {(userDetail.id && userDetail.id == itemMessage.user.id) ?
                            <span style={{ position: 'absolute', right: '20px' }} onClick={()=>{
                                deleteCommentAction(itemMessage?.id)
                            }}>
                                <KTSVG
                                path='/media/icons/duotune/arrows/arr061.svg'
                                className='svg-icon svg-icon-2x'
                                />
                            </span> : ""}
                    </div>
                )
            })
        ) : (
            <div className='single_comment_view d-flex align-items-start'>
                <div className='empty-comments text-center w-100'>
                    <Image
                        fluid
                        src={toAbsoluteUrl('/media/ilead/empty-comments.png')}
                        className='w-150px mb-4'
                    ></Image>
                    <h4 className='text-gray-900'>No Comments Yet</h4>
                    <span className='text-gray-500'>Be the first to comment</span>
                </div>
            </div>
        )
    }

    return (
        <Modal show={show} onHide={handleClose} className="post-listing">
            <Modal.Header closeButton>
                <Modal.Title>  {data?.topic_id == 1 ? "Announcement" : "Birthday"} </Modal.Title>
            </Modal.Header>
            <Modal.Body><div className={`card post-card overflow-hidden ${data?.isactive ? '' : 'hidePost'}`}>
                <div className='card-header p-6 align-items-start'>
                    <div className='d-flex justify-content-between flex-nowrap w-100'>
                        <div className='d-flex align-items-start'>
                            <Link to={`/leaders/${data?.user?.id}`}>
                                <div
                                    className={
                                        !true
                                            ? 'symbol symbol-55px me-4 top-leader-profile'
                                            : 'symbol symbol-55px me-4 top-leader-profile'
                                    }
                                    style={{ borderColor: data?.leaderGIColor }}
                                >
                                    <Image
                                        src={
                                            data?.user?.profile
                                                ? `${API_URL}/containers/userprofile/download/${data?.user?.profile}`
                                                : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                        }
                                        onError={({ currentTarget }: any) => {
                                            currentTarget.onerror = null // prevents looping
                                            currentTarget.src = `${toAbsoluteUrl(
                                                './media/avatars/default-user.png'
                                            )}`
                                        }}
                                    ></Image>
                                </div>
                            </Link>
                            <div className='flex-grow-1'>
                                <Link
                                    to={`/leaders/${data?.user?.id}`}
                                    className='text-black text-hover-primary fs-5 fs-md-4 fw-bold'
                                >
                                    {checkDisplayValue(data?.user?.name)}
                                </Link>
                                <span className='text-gray-600 fw-semibold d-block fs-13'>
                                    {checkDisplayValue(data?.user?.companyname)}
                                </span>
                                <div className='d-flex align-items-center'>
                                    <span className='text-gray-600 fw-semibold d-block fs-13 me-3'>
                                        {moment(data?.updated_at).fromNow()}
                                    </span>
                                    <span className='round-separator me-3'></span>
                                    {data?.chapter_id === 0 ? (
                                        <Image
                                            src={toAbsoluteUrl('/media/ilead/publicpost.png')}
                                            height={13}
                                        ></Image>
                                    ) : data?.group_id != null ? (
                                        <Image
                                            src={toAbsoluteUrl('/media/ilead/groupicon.png')}
                                            height={13}
                                        ></Image>
                                    ) : data?.chapter_id != 0 &&
                                        data?.group_id === null &&
                                        data?.self_post_id === null ? (
                                        <Image
                                            src={toAbsoluteUrl('/media/ilead/privatepost.png')}
                                            height={13}
                                        ></Image>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='card-toolbar my-0'>
                            <div className='m-0 d-flex flex-row align-items-end'>
                                {data?.topic_id == 1 ? (
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip className='tooltop-auto-width text-black'>
                                                {data?.topics?.name}
                                            </Tooltip>
                                        }
                                    >
                                        <div className='post-type'>
                                            <div>
                                                <SVG
                                                    src='/media/ilead/post-type/announcement.svg'
                                                    width={20}
                                                    height={20}
                                                />
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                ) : (
                                    <></>
                                )}
                                <Dropdown
                                    align='end'
                                    id='dropdown-menu-align-end'
                                    className='post-action-dropdown me-7'
                                    style={{ marginTop: '-15px' }}
                                >
                                    <Dropdown.Toggle id='dropdown-autoclose-true'>
                                        <i className='fa-solid fa-ellipsis fs-2x text-black pe-0'></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {data?.isbookmarked ? (
                                            <Dropdown.Item
                                                eventKey='1'
                                                className='d-flex align-items-center px-5 py-3 fs-6'
                                                onClick={() => {
                                                    removeBookMark()
                                                }}
                                            >
                                                <i
                                                    className='fas fa-bookmark cursor-pointer ilead-text me-2'
                                                    style={{ fontSize: '24px' }}
                                                ></i>
                                                Remove Bookmark
                                            </Dropdown.Item>
                                        ) : (
                                            <Dropdown.Item
                                                eventKey='1'
                                                className='d-flex align-items-center px-5 py-3 fs-6'
                                                onClick={() => {
                                                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                                    setBookMark()
                                                }}
                                            >
                                                <i className='las la-bookmark fs-2x cursor-pointer text-black me-2'></i>
                                                Bookmark
                                            </Dropdown.Item>
                                        )}
                                        {data?.topic_id === 1 ||
                                            data?.topic_id === 3 ||
                                            data?.topic_id === 10 ||
                                            (data?.topic_id === 12 && userDetail?.id == data?.user_id) ||
                                            (data?.topic_id === 7 &&
                                                (userDetail?.id == data?.thankyou_receive_userid ||
                                                    userDetail?.id == data?.thankyou_send_userid)) ||
                                            (data?.topic_id === 20 &&
                                                (userDetail?.id == data?.business_referral_receiver ||
                                                    userDetail?.id == data?.business_referral_sender)) ||
                                            (data?.topic_id === 21 &&
                                                (userDetail?.id == data?.business_connect_receiver ||
                                                    userDetail?.id == data?.business_connect_sender)) ||
                                            (data?.topic_id === 22 &&
                                                (userDetail?.id == data?.business_generate_receiver ||
                                                    userDetail?.id == data?.business_generate_sender)) ? (
                                            <Dropdown.Item eventKey='2' className='px-5 py-3'>
                                                <RWebShare
                                                    data={{
                                                        text: `${data?.description ?? ''}`,
                                                        url: `${data?.posturl ?? ''}`,
                                                        title: `${data?.topics?.name ?? ''}`,
                                                    }}
                                                >
                                                    <Button
                                                        variant='link'
                                                        className='nav-link p-0 w-auto h-auto fs-6'
                                                    >
                                                        <i className='las la-share fs-2x text-black me-2'></i>
                                                        Share
                                                    </Button>
                                                </RWebShare>
                                            </Dropdown.Item>
                                        ) : (
                                            <></>
                                        )}

                                        <div>
                                            {data?.canHide ? (
                                                <Dropdown.Item eventKey='3' className='px-5 py-3'>
                                                    {userDetail?.role_id == 4 ||
                                                        userDetail?.role_id == 5 ||
                                                        userDetail?.role_id == 6 ||
                                                        (data?.canHide &&
                                                            !(data.topic_id == 20) &&
                                                            !(data.topic_id == 21) &&
                                                            !(data.topic_id == 22)) ||
                                                        (data?.canHide &&
                                                            data.topic_id == 20 &&
                                                            (userDetail?.id == data?.business_referral_receiver ||
                                                                userDetail?.id == data?.business_referral_sender)) ||
                                                        (data?.canHide &&
                                                            data.topic_id == 21 &&
                                                            (userDetail?.id == data?.business_connect_receiver ||
                                                                userDetail?.id == data?.business_connect_sender)) ||
                                                        (data?.canHide &&
                                                            data.topic_id == 22 &&
                                                            (userDetail?.id == data?.business_generate_receiver ||
                                                                userDetail?.id == data?.business_generate_sender)) ? (
                                                        <Button
                                                            variant='link'
                                                            className='text-start d-flex p-0 w-auto h-auto me-2 fs-6'
                                                            onClick={() => {
                                                                showHideAction()
                                                            }}
                                                            disabled={loading?.hide}
                                                        >
                                                            <i className='las la-low-vision fs-2x text-black'></i>
                                                            {loading?.hide
                                                                ? 'Loading...'
                                                                : data?.isactive
                                                                    ? 'Hide'
                                                                    : 'Show'}
                                                        </Button>
                                                    ) : (
                                                        <> </>
                                                    )}
                                                </Dropdown.Item>
                                            ) : (
                                                <> </>
                                            )}
                                            {data?.canDeletePost ? (
                                                <Dropdown.Item eventKey='3' className='px-5 py-3'>
                                                    {userDetail?.role_id == 4 ||
                                                        userDetail?.role_id == 5 ||
                                                        userDetail?.role_id == 6 ||
                                                        data?.canDeletePost ? (
                                                        <Button
                                                            variant='link'
                                                            className='text-start d-flex p-0 w-auto h-auto me-2 fs-6'
                                                            // data-bs-toggle='modal'
                                                            // data-bs-target={`#delete_post_modal_open`}
                                                            onClick={() => {
                                                                setShowDeleteModal(true)
                                                                getPostComment()
                                                                setdeleteShowModel(true)
                                                            }}
                                                        >
                                                            <i className='las la-trash fs-2x text-black'></i>
                                                            Delete
                                                        </Button>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Dropdown.Item>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>

                                {/* <span className='mt-2 fs-13 post-type text-end'>{item?.topics?.name ?? ''}</span> */}
                            </div>
                        </div>
                    </div>
                </div>
                {data?.description?.trim() == 'Top GI Performers' ||
                    data?.description?.trim() == 'Top BI Performers' ?
                    <>
                        <div className='card-body pt-3 pb-3 px-0 position-relative overflow-hidden '>
                            <div className="description-content winner-post ">
                                <div className='media '>
                                    <div style={AnimationStyle} className="position-absolute w-100 h-100">
                                        <div className='popper'>
                                            <span id={`rewardLeft-${data?.id}`} />
                                        </div>
                                        <div className='popper'>
                                            <span id={`rewardRight-${data?.id}`} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='winner-title d-flex flex-column'>
                                            <b style={{ fontWeight: 'bold' }}> {checkDisplayValue(data?.description)}</b>
                                            <div className='ribbon' style={{ backgroundColor: 'green' }}>
                                                {JSON.parse(data?.others)?.month}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='winner-wrapper'>
                                        {JSON.parse(data?.others)?.data?.map((item: any, index: any) => (
                                            <>
                                                <div className='leader'>
                                                    <div className='leader-avatar'>

                                                        <Image
                                                            style={{ width: '100%' }}
                                                            src={
                                                                item?.profile
                                                                    ? `${API_URL}/containers/userprofile/download/${item?.profile}`
                                                                    : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                                            }
                                                            onError={({ currentTarget }: any) => {
                                                                currentTarget.onerror = null // prevents looping
                                                                currentTarget.src = `${toAbsoluteUrl(
                                                                    './media/avatars/default-user.png'
                                                                )}`
                                                            }}
                                                        ></Image>

                                                    </div>
                                                    <div className='leader-details'>
                                                        <h4>{item?.name}</h4>
                                                        <h3>{item?.total_points}</h3>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </> :
                    <div className='card-body pt-3 pb-3 ps-0 pe-0'>
                        <div
                            className='description-content'
                            style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
                        >
                            <div className='fs-7 fs-md-6 fw-normal text-black mb-5 px-6'>
                                <div>
                                    <span>
                                        {convertTextToLinks(checkDisplayValue(data?.description))}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {data.usertopicmedia && data.usertopicmedia.length > 0 ? (
                            <div className='media mb-2'>
                                <Carousel controls={false} >
                                    {data.usertopicmedia.map((image: any, imageIndex: any) => {
                                        let itemLast = image.file.split('.').pop()
                                        if (
                                            itemLast == 'jpeg' ||
                                            itemLast == 'jpeg' ||
                                            itemLast == 'png' ||
                                            itemLast == 'jpg'
                                        ) {
                                            return (
                                                <Carousel.Item key={imageIndex} >
                                                    <Image
                                                        src={
                                                            image.file
                                                                ? `${API_URL}/containers/topicimages/download/${image.file}`
                                                                : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                                        }
                                                        onError={({ currentTarget }: any) => {
                                                            currentTarget.onerror = null // prevents looping
                                                            currentTarget.src = `${toAbsoluteUrl(
                                                                './media/avatars/default-user.png'
                                                            )}`
                                                        }}
                                                        className='w-100'
                                                    />
                                                </Carousel.Item>
                                            )
                                        } else if (itemLast == 'pdf') {
                                            return (
                                                <Carousel.Item key={imageIndex}>
                                                    <Image
                                                        src={'./media/ilead/pdf.png'}
                                                        onError={({ currentTarget }: any) => {
                                                            currentTarget.onerror = null // prevents looping
                                                            currentTarget.src = `${toAbsoluteUrl(
                                                                './media/avatars/default-user.png'
                                                            )}`
                                                        }}
                                                        onClick={() => handleToshow(image?.file)}
                                                        className='w-100'
                                                    />
                                                </Carousel.Item>
                                            )
                                        } else if (
                                            itemLast == 'doc' ||
                                            itemLast == 'docm' ||
                                            itemLast == 'docx' ||
                                            itemLast == 'dot'
                                        ) {
                                            return (
                                                <Carousel.Item key={imageIndex}>
                                                    <Image
                                                        src={'./media/ilead/docs2.webp'}
                                                        onError={({ currentTarget }: any) => {
                                                            currentTarget.onerror = null // prevents looping
                                                            currentTarget.src = `${toAbsoluteUrl(
                                                                './media/avatars/default-user.png'
                                                            )}`
                                                        }}
                                                        className='w-100'
                                                        onClick={() => handleToshow(image?.file)}
                                                    />
                                                </Carousel.Item>
                                            )
                                        } else if (
                                            itemLast == 'xlsx' ||
                                            itemLast == 'xlsm' ||
                                            itemLast == 'xlsb' ||
                                            itemLast == 'xltx'
                                        ) {
                                            return (
                                                <Carousel.Item key={imageIndex}>
                                                    <Image
                                                        src={'./media/ilead/sheets1.webp'}
                                                        onError={({ currentTarget }: any) => {
                                                            currentTarget.onerror = null // prevents looping
                                                            currentTarget.src = `${toAbsoluteUrl(
                                                                './media/avatars/default-user.png'
                                                            )}`
                                                        }}
                                                        className='w-100'
                                                        onClick={() => handleToshow(image?.file)}
                                                    />
                                                </Carousel.Item>
                                            )
                                        } else if (
                                            itemLast == 'pptx' ||
                                            itemLast == 'pptm' ||
                                            itemLast == 'ppt'
                                        ) {
                                            return (
                                                <Carousel.Item key={imageIndex}>
                                                    <Image
                                                        src={'./media/ilead/ppt2.webp'}
                                                        onError={({ currentTarget }: any) => {
                                                            currentTarget.onerror = null // prevents looping
                                                            currentTarget.src = `${toAbsoluteUrl(
                                                                './media/avatars/default-user.png'
                                                            )}`
                                                        }}
                                                        className='w-100'
                                                        onClick={() => handleToshow(image?.file)}
                                                    />
                                                </Carousel.Item>
                                            )
                                        }
                                    })}
                                </Carousel>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                }

                <div className='comment-count d-flex flex-nowrap justify-content-between px-2 px-4 pb-3 text-gray-700 fs-13'>
                    <div
                        className='like_counter'
                        data-bs-toggle='modal'
                        data-bs-target={`#post_icaniwill_leader_view${index}`}
                        onClick={() => {
                            getIcaniwillLeaderList()
                            setShowLikeModal(true)
                        }}
                    >
                        {data?.totallikes === 0
                            ? ''
                            : isLiked() === false && data?.totallikes === 1
                                ? `I Can I Will by ${data?.usertopiclikes[0]?.user?.name}`
                                : isLiked() === false && data?.totallikes === 2
                                    ? `I Can I Will by ${data?.usertopiclikes[0]?.user?.name} and ${data?.totallikes - 1
                                    } other`
                                    : isLiked() === false && data?.totallikes > 2
                                        ? `I Can I Will by ${data?.usertopiclikes[0]?.user?.name} and ${data?.totallikes - 1
                                        } others`
                                        : isLiked() === true && data?.totallikes === 1
                                            ? 'I Can I Will by you'
                                            : isLiked() === true && data?.totallikes === 2
                                                ? `I Can I Will by you and ${data?.totallikes - 1} other`
                                                : isLiked() === true && data?.totallikes > 2
                                                    ? `I Can I Will by you and ${data?.totallikes - 1} others`
                                                    : ''}
                    </div>
                    <div
                        className='comment_counter'
                        data-bs-toggle='modal'
                        data-bs-target={`#pinned_post_comment_view`}
                        onClick={() => {
                            getPostComment()
                            setShowCommentModal(true)
                        }}
                    >
                        {/* {commentData?.totalcomments ?? 0}  Comments */}
                        {`${data?.totalcomments
                            ? `${data?.totalcomments > 1
                                ? data?.totalcomments + ' Comments'
                                : data?.totalcomments + ' Comment'
                            }`
                            : 0 + ' Comment'
                            }`}
                    </div>
                </div>
                <div className='card-footer px-3 py-3'>
                    <ul className='nav py-0 d-flex  justify-content-between flex-nowrap'>
                        <li className='nav-item w-50 justify-content-center d-flex action position-relative pe-3'>
                            <div
                                onClick={() => {
                                    if (loading?.like == false) {
                                        likePostAction()
                                    }
                                }}
                                className='nav-link btn-color-gray-800 px-2 fw-normal w-100 position-relative fs-7 fs-md-6 cursor-pointer'
                            >
                                <Heart isClick={isLiked()} onClick={() => { }}></Heart>
                                {loading?.like ? 'Please Wait...' : 'I Can I Will'}
                            </div>
                        </li>
                        <li className='nav-item w-50 justify-content-center d-flex pe-3'>
                            <Button
                                variant='link'
                                className='nav-link btn btn-sm btn-color-gray-800 px-2 fw-normal w-100 position-relative fs-7 fs-md-6'
                                data-bs-toggle='modal'
                                data-bs-target={`#pinned_post_comment_view`}
                                onClick={() => {
                                    setShowCommentModal(true)
                                    getPostComment()
                                }}
                            >
                                <i className='bi bi-chat'></i> Comment
                            </Button>
                        </li>
                    </ul>
                </div>
            </div></Modal.Body>
            {/* comment model  */}
            {/* showCommentModal */}
            <Modal show={showCommentModal} onHide={() => setShowCommentModal(false)}>

                <div className='modal-content'>
                    <div className='modal-header p-3'>
                        <h5 className='modal-title text-center w-100'>Comments</h5>
                        <div
                            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={() => setShowCommentModal(false)}
                        >
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr061.svg'
                                className='svg-icon svg-icon-2x'
                            />
                        </div>
                    </div>
                    {/* {commentData} */}
                    {loading.list ? (
                        <h5 className='text-center'>Loading...</h5>
                    ) : (
                        <div className='modal-body comment-body'>{commentList()}</div>
                    )}
                    <div className='modal-footer py-2'>
                        <div className='symbol symbol-40px'>
                            <Image
                                src={
                                    userDetail?.profile
                                        ? `${API_URL}/containers/userprofile/download/${userDetail?.profile}`
                                        : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                }
                                onError={({ currentTarget }: any) => {
                                    currentTarget.onerror = null // prevents looping
                                    currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                }}
                            ></Image>
                        </div>
                        <div className='comment_text_box m-0 flex-1'>
                            {/* <Form> */}
                                <Form.Control
                                    className='border-0 fw-normal text-black fs-6'
                                    as='textarea'
                                    placeholder='Write your comment...'
                                    value={formik.values.chattext}
                                    onChange={(e: any) => {
                                        if(e.target.value.trimStart().length <= 250){
                                            formik.setFieldValue('chattext', e.target.value.trimStart())
                                        }
                                    }}
                                    autoFocus
                                    onKeyDown={(e)=>{
                                        if(e.key === "Enter"){
                                          formik.handleSubmit()
                                          return
                                        }
                                      }}
                                />
                                <div className={`d-flex justify-content-end ${formik.values.chattext.length >= 250 ? "text-danger" : ""}`}>
                                    <b>{250 - formik.values.chattext.length}{" "} Character Left</b>
                                </div>
                            {/* </Form> */}
                        </div>

                        <Button
                            variant='link'
                            type='button'
                            className='p-0 m-0 symbol symbol-40px symbol-circle'
                            disabled={loading.send}
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                        >
                            <div className='symbol-label bg-primary'>
                                <i className='fa-regular fa-paper-plane text-white'></i>
                            </div>
                        </Button>
                    </div>
                </div>
                {/* </Modal.Body> */}
            </Modal>

            {/* delete modal open */}






            <Modal show={showLikeModal} onHide={() => setShowLikeModal(false)}>

                <div className='modal-content'>
                    <div className='modal-header p-3'>
                        <h5 className='modal-title text-center w-100'>I CAN I WILL by Leaders</h5>
                        <div
                            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={() => setShowLikeModal(false)}
                        >
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr061.svg'
                                className='svg-icon svg-icon-2x'
                            />
                        </div>
                    </div>
                    {/* {commentData} */}
                    {loading.list ? (
                        <h5 className='text-center'>Loading...</h5>
                    ) : (
                        <div className='modal-body comment-body'>{icaniwillLeaderList()}</div>
                    )}
                    {/* <div className='modal-footer py-2'>
                        <div className='symbol symbol-40px'>
                            <Image
                                src={
                                    userDetail?.profile
                                        ? `${API_URL}/containers/userprofile/download/${userDetail?.profile}`
                                        : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                }
                                onError={({ currentTarget }: any) => {
                                    currentTarget.onerror = null // prevents looping
                                    currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                }}
                            ></Image>
                        </div>
                        <div className='comment_text_box m-0 flex-1'>
                            <Form>
                                <Form.Control
                                    className='border-0 fw-normal text-black fs-6'
                                    type='text'
                                    placeholder='Write your comment...'
                                    value={formik.values.chattext}
                                    onChange={(e: any) => {
                                        formik.setFieldValue('chattext', e.target.value)
                                    }}
                                    autoFocus
                                />
                            </Form>
                        </div>

                        <Button
                            variant='link'
                            type='button'
                            className='p-0 m-0 symbol symbol-40px symbol-circle'
                            disabled={loading.send}
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                        >
                            <div className='symbol-label bg-primary'>
                                <i className='fa-regular fa-paper-plane text-white'></i>
                            </div>
                        </Button>
                    </div> */}
                </div>
                {/* </Modal.Body> */}
            </Modal>

            <Modal className="deleteModel" show={deleteModel} onHide={() => setdeleteShowModel(false)} style={{ zIndex: "9999" }}>
                <Modal.Header closeButton>
                    <Modal.Title>  <h5 className='modal-title'>Are you sure you want to delete this post?</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body><p>This will delete this post permanently. You cannot undo this action.</p></Modal.Body>
                <Modal.Footer>


                    <Button variant="secondary" onClick={() => { setdeleteShowModel(false) }}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={loading?.delete} onClick={() => {
                        deletePostAction()
                    }}>
                        {loading?.delete ? 'Please Wait...' : 'Yes'}
                    </Button>
                </Modal.Footer>
            </Modal>





            <div className='modal fade show' tabIndex={-1} id={`pinned_post_comment_view`}>
                <div className='modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-dialog-centered modal-md'>
                    <div className='modal-content'>
                        <div className='modal-header p-3'>
                            <h5 className='modal-title text-center w-100'>Comments </h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        {loading.list ? (
                            <h5 className='text-center'>Loading...</h5>
                        ) : (
                            <div className='modal-body comment-body'>{commentList()}</div>
                        )}
                        <div className='modal-footer py-2'>
                            <div className='symbol symbol-40px'>
                                <Image
                                    src={
                                        userDetail?.profile
                                            ? `${API_URL}/containers/userprofile/download/${userDetail?.profile}`
                                            : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                    }
                                    onError={({ currentTarget }: any) => {
                                        currentTarget.onerror = null // prevents looping
                                        currentTarget.src = `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                                    }}
                                ></Image>
                            </div>
                            <div className='comment_text_box m-0 flex-1'>
                                <Form>
                                    <Form.Control
                                        className='border-0 fw-normal text-black fs-6'
                                        as='textarea'
                                        placeholder='Write your comment...'
                                        // value={formik.values.chattext}
                                        onChange={(e: any) => {
                                            // formik.setFieldValue('chattext', e.target.value)
                                        }}
                                        autoFocus
                                    />
                                </Form>
                            </div>

                            <Button
                                variant='link'
                                type='button'
                                className='p-0 m-0 symbol symbol-40px symbol-circle'
                                disabled={loading.send}
                                onClick={() => {
                                    // formik.handleSubmit()
                                }}
                            >
                                <div className='symbol-label bg-primary'>
                                    <i className='fa-regular fa-paper-plane text-white'></i>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {/* ==================Comment Model End ==================== */}

            {/* ================== I CAN I WILL by Leaders Model Start ==================== */}
            <div className='modal fade' tabIndex={-1} id={`pinned_post_icaniwill_leader_view`}>
                <div className='modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-dialog-centered modal-md'>
                    <div className='modal-content'>
                        <div className='modal-header p-3'>
                            <h5 className='modal-title text-center w-100'>I CAN I WILL by Leaders</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        {loading.list ? (
                            <h5 className='text-center'>Loading...</h5>
                        ) : (
                            <div className='modal-body comment-body'>{icaniwillLeaderList()}</div>
                        )}
                    </div>
                </div>
            </div>
            {/* ==================I CAN I WILL by Leaders Model End ==================== */}
        </Modal>

    )
}