/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {UpdateMemberStatusAPI, deleteVisitorpanel} from '../../core/_requests'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTIcon, QUERIES } from '../../../../../../_metronic/helpers'
import Dropdown from 'react-bootstrap/Dropdown';
import { UserContext } from '../../../../../../contextStore'

type Props = {
  id: ID
  value:any
}

const VisitorpanelMembershipCell: FC<Props> = ({id,value}) => {
  const {setItemIdForUpdate} = useListView();
  const { userDetail, chapterID, reload ,setMessage} = useContext(UserContext)
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteVisitorpanel(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.VISITORPANELS_LIST}-${query}`])
    },
  })

  const UpdateStatus=async (status:any)=>{
    await UpdateMemberStatusAPI({status,id}).then((res:any)=>{
      //console.log("res",res.data)
      if(res?.data ){
        // resetForm()
        // navigate('/visitor-panel')
        setMessage('success', 'Invitations Update successfully')
        queryClient.invalidateQueries([`${QUERIES.VISITORPANELS_LIST}-${query}`])

        // handleClose()
      }
    }).catch((error:any)=>{
      console.log("error",error)
      if (error?.response?.data?.error?.message) {
        console.log("error?.response?",error?.response?.status)
        if(error?.response?.status == 500 )
        {
        setMessage('error', 'Something went wrong')
        }else{

          setMessage('error', error?.response?.data?.error?.message)
        }
      } else {
        setMessage('error', 'Something went wrong')
      }
    })
}

  return (
    <>
     <Dropdown >
      <Dropdown.Toggle id="dropdown-basic" className='form-select form-select-lg mb-3 mt-2' > 
      {value == 1 ?  "Empty" : value == 2 ? "Interested": value  == 3 ? "Not Interested":value  == 4 ? "Joined":"-" }
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item  className='menu-item px-3' onClick={()=>{UpdateStatus(1)}} active={value==1} >Empty </Dropdown.Item>
        <Dropdown.Item  className='menu-item px-3' onClick={()=>{UpdateStatus(2)}}  active={value==2} >Interested</Dropdown.Item>
        <Dropdown.Item   className='menu-item px-3' onClick={()=>{UpdateStatus(3)}} active={value==3} >Not Interested</Dropdown.Item>
        <Dropdown.Item   className='menu-item px-3' onClick={()=>{UpdateStatus(4)}} active={value==4} >Joined</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
      {/* <a
        href='#'
        className='btn btn-light btn-active-light-primary fs-5 w-100'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        -
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a> */}
      {/* begin::Menu */}
      {/* <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-5 w-200px py-4'
        data-kt-menu='true'
      > */}
        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Interested
          </a>
        </div> */}


        {/* end::Menu item */}
        {/* <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Not Interested
          </a>
        </div> */}


        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-visitorpanels-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            Joined
          </a>
        </div> */}
        {/* end::Menu item */}
      {/* </div> */}
      {/* end::Menu */}
    </>
  )
}

export {VisitorpanelMembershipCell}
