import Multiselect from "multiselect-react-dropdown";
// import React from "react";
import Select from 'react-select'

import React, { useContext, useEffect, useState } from "react";
import { getEventList, getExportGIreportYear, getLeaderBroad, getUserList } from "./core/_request";
import { UserContext } from "../../../contextStore";
import { checkDisplayValue, formatDate } from "../../../utils";
import { apiUrl } from "../../../apiurl";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from "moment";
import download from "downloadjs";


const options = [
  { value: 'all', label: 'All Dates' },
]

const Leadername = [
  { value: '', label: 'All Leader' }

]



const Points = [
  { value: 'total_points', label: 'Total Points' },
  { value: 'isattended', label: 'Attendance' },
  { value: 'visitorsinvited', label: 'Visitor Invited' },
  { value: 'visitorsattended', label: 'Visitor Attended' },
  { value: 'visitorsjoined', label: 'Member Joined' },
  { value: 'thankyougiven', label: 'Thank You Given' },
  { value: 'thankyourec', label: 'Thank you recieved' },
  { value: 'gma', label: 'Group Meeting Join' },
  { value: 'feedbackgiven', label: 'Feedback Given' },
  { value: 'helpposts', label: 'Help'}
]


const Order = [
  { value: 'desc', label: 'Descending' },
  { value: 'asc', label: 'Ascending' },
]

const LeaderBoardListing = () => {
  const { userDetail, setMessage, chapterID, reload } = useContext(UserContext)
  const chapter_id = chapterID ?? ''
  const limit = 3
  const [leaderData, setLeaderData] = useState<any>([])
  const [eventList, setEventList] = useState<any>([])
  const [userList, setUserList] = useState<any>([])
  const [skip, setSkip] = useState<any>(0)
  const [leaderID, setLeaderID] = useState<any>("")
  const [eventID, setEventID] = useState<any>("all")
  const [sortclm, setSortclm] = useState<any>("total_points")
  const [order, setOrder] = useState<any>("desc")
  const [hasMoreFor, setHasMoreFor] = useState(true);
  const [filter, setFilter] = useState(false)

  useEffect(() => {
    setSkip(0)
    setHasMoreFor(true)
    getdata()

  }, [reload])


  const getdata = async () => {

    let eventId = eventID ? `event_id=${eventID}` : ``

    let leaderId = leaderID ? `leader_id=${leaderID}` : ``
    await getLeaderBroad(limit, skip, chapterID, sortclm, order, `${leaderId}&${eventId}`).then((resDATA: any) => {

      console.log("------- jui --------")
      console.log("resDATA", resDATA)
      console.log("------- hiue --------")
      let newData = resDATA ?? []
      if (filter) {
        if (newData.length == 0) {
          setLeaderData(newData)
          setHasMoreFor(false)
        } else {
          setLeaderData(newData)
          setSkip(skip + limit);
        }
      } else {
        if (newData.length == 0) {
          setLeaderData(leaderData)
          setHasMoreFor(false)
        } else {
          setLeaderData([...leaderData, ...newData])
          setSkip(skip + limit);
        }

      }

      // setLeaderData(resDATA ?? [])
      // setSkip(skip + limit)
      setFilter(false)

    }).catch((eeror: any) => {
      console.log("resDATA", eeror)

    })
  }


  const getEventDateList = async () => {

    await getEventList(chapterID,moment().format('YYYY-MM-DD')).then((resDATA: any) => {

      console.log("resDATA", resDATA)

      const OptionEvent = resDATA.map((item: any) => {
        return {
          value: item?.id,
          label: formatDate(item?.event_date)
        };
      });
      setEventList([...options, ...OptionEvent] ?? [])

    }).catch((eeror: any) => {
      console.log("resDATA", eeror)

    })


  }

  const getUsersList = async () => {
    await getUserList(chapterID).then((resDATA: any) => {

      console.log("------- jui --------")
      console.log("user data", resDATA)
      console.log("------- hiue --------")
      const OptionLeader = resDATA.map((item: any) => {
        return {
          value: item?.id,
          label: item?.name
        };
      });
      setUserList([ ...OptionLeader] ?? [])
    }).catch((eeror: any) => {
      console.log("resDATA", eeror)

    })
  }


  useEffect(() => {
    getdata()
    getEventDateList()
    getUsersList()
  }, [])

  useEffect(() => {
    if (filter) {
      getdata()

    }

  }, [filter])
  console.log("userList", userList)


  
  const GIreportYear = async () => {
    await getExportGIreportYear().then((res: any) => {
      download(res.data, 'GI reports.xls', 'application/download');
      setMessage("success", "GI file downloaded successfully")
      // setPdfLoading(0)
    }).catch(() => {
      setMessage("error", "GI file download failed")
      // setPdfLoading(0)
    })
  }

  // console.log("----",OptionLeader)
  return (
    <>

      <div className="row mx-0 flex-md-block ">
        <div className=' col-sm-12 col-md-6 px-0 px-md-3 col-lg-6'><h1 className='inv-hst sm-ms-2'> Leaders Board </h1>
        </div>

        <div className=' col-sm-12 col-md-6 px-0 px-md-3 col-lg-6 d-flex justify-content-end'>
        <button className="btn btn-primary py-4 py-sm-0 waves-effect waves-light " 
             style={{height:"46px"}}
             onClick={()=>{
               GIreportYear()
             }}> Export GI Report</button>
             
        </div>
      </div>
     
      


      <div className='mt-5 py-8 px-5 index-hdr justify-content-between tyca-hdr row flex-row  mx-0 card ilead__card'>

        <div className='row col-lg-10 col-md-12 mx-0 filter-div'>
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
            <div className='filter-div  index-filter mx-0'>
              <div className='fltr-lbl'>
                {' '}
                <h4 className='text-dark fs-5 fw-bold'> Session Date Range: </h4>{' '}
              </div>
              <div className=' card rounded-0 py-3'>
                <Select
                  // isLoading={loadingList}
                  options={eventList}
                  className='mb-5 text-dark'
                  classNamePrefix='Select'
                  placeholder='Select date'
                  value={eventID ? eventList.filter((x: any) => x.value == eventID) : []}
                  onChange={(e: any) => {
                    setEventID(e.value)
                    // formik.setFieldValue('admin_approve', e.value)
                  }}
                />
              </div>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
            <div className='filter-div  index-filter mx-0'>
              <div className='fltr-lbl'>
                {' '}
                <h4 className='text-dark fs-5 fw-bold'> Select Leader Name </h4>{' '}
              </div>
              <div className=' card rounded-0 py-3'>
              <select
                  className="form-select px-5 py-3 form-select-sm"
                  aria-label=".form-select-sm example"
                  onChange={(event: any) => {
                    setLeaderID(event.target.value); 
                  }}
                  value={leaderID} 
                >
                  <option value="">All Leaders</option>
                  {userList.map((leader: any) => (
                    <option key={leader.value} value={leader.value}>
                      {leader.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
            <div className='filter-div  index-filter mx-0'>
              <div className='fltr-lbl'>
                {' '}
                <h4 className='text-dark fs-5 fw-bold'> Sort By Points </h4>{' '}
              </div>
              <div className=' card rounded-0 py-3'>

                <Select
                  // isLoading={loadingList}
                  options={Points}
                  className='mb-5 text-dark'
                  classNamePrefix='Select'
                  placeholder='Select culonmus'
                  value={sortclm ? Points.filter((x: any) => x.value == sortclm) : []}
                  onChange={(e: any) => {
                    setSortclm(e.value)
                    // formik.setFieldValue('admin_approve', e.value)
                  }}
                />


              </div>
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
            <div className='filter-div  index-filter mx-0'>
              <div className='fltr-lbl'>
                {' '}
                <h4 className='text-dark fs-5 fw-bold'> Sort Order </h4>{' '}
              </div>
              <div className=' card rounded-0 py-3'>

                <Select
                  // isLoading={loadingList}
                  options={Order}
                  className='mb-5 text-dark'
                  classNamePrefix='Select'
                  placeholder='Select culonmus'
                  value={order ? Order.filter((x: any) => x.value == order) : []}
                  onChange={(e: any) => {
                    setOrder(e.value)
                    // formik.setFieldValue('admin_approve', e.value)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='text-end col-sm-12 pe-15 pe-md-5 col-lg-2'>
          <button
            type='submit'
            onClick={() => {
              setFilter(true)
              setSkip(0)
              setHasMoreFor(true)
            }}
            className='btn btn-primary text-nowrap mt-0 w-auto w-lg-100 mt-md-10 waves-effect waves-light'
          >
            <i className='fa fa-filter text-white' aria-hidden='true'></i> Filter
          </button>
        </div>
      </div>

      <div className='mt-5 py-8 px-5 index-hdr justify-content-between tyca-hdr row flex-row  mx-0 card ilead__card'>




        <InfiniteScroll
          dataLength={leaderData?.length}
          next={getdata}
          hasMore={hasMoreFor}
          // height={600}
          loader={<></>}
        >
          {leaderData.length > 0
            ? leaderData.map((item: any, i: any) => {
              let profile = item?.userprofile
              return (
                <div className='mt-5 py-3 px-5 index-hdr justify-content-between tyca-hdr card ilead__card'>
                  <div className='row mx-0 py-5 border-bottom border-gray-300'>
                    <div className='col-8 col-md-6 d-flex'>
                      <div style={{ width: '50px' }} className='me-3'>
                      
                        <img
                          src={
                            profile
                              ? `${apiUrl}/containers/userprofile/download/${profile}`
                              : `${toAbsoluteUrl('./media/avatars/default-user.png')}`
                          }
                          alt={`Not Found.`}
                          onError={({ currentTarget }: any) => {
                            currentTarget.onerror = null // prevents looping
                            currentTarget.src = `${toAbsoluteUrl(
                              './media/avatars/default-user.png'
                            )}`
                          }}
                          className='rounded-circle img-fluid'
                        />
                      </div>

                      <div className='pt-2'>
                        <h5 className='fw-bold fs-5 mb-0 text-blk text-start'>
                          {' '}
                          {checkDisplayValue(item?.username)}
                        </h5>
                        <p className='text-blk fs-6 mb-0 text-start text-gray-800'>
                          {' '}
                          {checkDisplayValue(item?.usercompany)}{' '}
                        </p>
                      </div>
                    </div>

                    <div className='col-4 col-md-6 d-flex justify-content-end'>
                      <div className='bg-primary text-white  px-4 py-4 text-center rounded'>
                        <p className='mb-0 fs-5'> {item?.total_points ? item?.total_points : 0} </p>
                        <p className='mb-0 fs-7'> Points </p>
                      </div>
                    </div>
                  </div>

                  <div className='row mx-0 pt-5 '>
                    <div className='col-6 col-md-3 col-lg-3 col-xl-2 mb-5'>
                      <div className='leader-point-listing d-flex my-auto flex-column align-items-center justify-content-between rounded py-4'>
                        <div className='leader-points p-3'>
                          {' '}
                          {item?.isattended ? item?.isattended : 0}{' '}
                        </div>
                        <div className='text-center fs-7 fw-bold'> Attendance </div>
                      </div>
                    </div>

                    <div className='col-6 col-md-3 col-lg-3 col-xl-2 mb-5'>
                      <div className='leader-point-listing d-flex my-auto flex-column align-items-center justify-content-between rounded py-4'>
                        <div className='leader-points p-3'>
                          {' '}
                          {item?.visitorsinvited ? item?.visitorsinvited : 0}{' '}
                        </div>
                        <div className='text-center fs-7 fw-bold'> Visitors Invited </div>
                      </div>
                    </div>
                    <div className='col-6 col-md-3 col-lg-3 col-xl-2 mb-5'>
                      <div className='leader-point-listing d-flex my-auto flex-column align-items-center justify-content-between rounded py-4'>
                        <div className='leader-points p-3'>
                          {' '}
                          {item?.visitorsattended ? item?.visitorsattended : 0}{' '}
                        </div>
                        <div className='text-center fs-7 fw-bold'> Visitors Attended </div>
                      </div>
                    </div>
                    <div className='col-6 col-md-3 col-lg-3 col-xl-2 mb-5'>
                      <div className='leader-point-listing d-flex my-auto flex-column align-items-center justify-content-between rounded py-4'>
                        <div className='leader-points p-3'>
                          {' '}
                          {item?.visitorsjoined ? item?.visitorsjoined : 0}{' '}
                        </div>
                        <div className='text-center fs-7 fw-bold'> Member's Joined </div>
                      </div>
                    </div>
                    <div className='col-6 col-md-3 col-lg-3 col-xl-2 mb-5'>
                      <div className='leader-point-listing d-flex my-auto flex-column align-items-center justify-content-between rounded py-4'>
                        <div className='leader-points p-3'>
                          {' '}
                          {item?.thankyougiven ? item?.thankyougiven : 0}{' '}
                        </div>
                        <div className='text-center fs-7 fw-bold'> Thank You Given </div>
                      </div>
                    </div>
                    <div className='col-6 col-md-3 col-lg-3 col-xl-2 mb-5'>
                      <div className='leader-point-listing d-flex my-auto flex-column align-items-center justify-content-between rounded py-4'>
                        <div className='leader-points p-3'>
                          {' '}
                          {item?.thankyourec ? item?.thankyourec : 0}{' '}
                        </div>
                        <div className='text-center fs-7 fw-bold'> Thank You recieved </div>
                      </div>
                    </div>

                    <div className='col-6 col-md-3 col-lg-3 col-xl-2 mb-5'>
                      <div className='leader-point-listing d-flex my-auto flex-column align-items-center justify-content-between rounded py-4'>
                        <div className='leader-points p-3'> {item?.gma ? item?.gma : 0} </div>
                        <div className='text-center fs-7 fw-bold'> Group Meeting Attended </div>
                      </div>
                    </div>

                    <div className='col-6 col-md-3 col-lg-3 col-xl-2 mb-5'>
                      <div className='leader-point-listing d-flex my-auto flex-column align-items-center justify-content-between rounded py-4'>
                        <div className='leader-points p-3'>
                          {' '}
                          {item?.feedbackgiven ? item?.feedbackgiven : 0}{' '}
                        </div>
                        <div className='text-center fs-7 fw-bold'> Feedback Given </div>
                      </div>
                    </div>

                    <div className='col-6 col-md-3 col-lg-3 col-xl-2 mb-5'>
                      <div className='leader-point-listing d-flex my-auto flex-column align-items-center justify-content-between rounded py-4'>
                        <div className='leader-points p-3'>
                          {' '}
                          {item?.helpposts ? item?.helpposts : 0}{' '}
                        </div>
                        <div className='text-center fs-7 fw-bold'> Help </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
            : (<>
              <div className='mt-5 py-3 px-5 index-hdr justify-content-between tyca-hdr card ilead__card'>
                Data Not Found
              </div>

            </>)}

        </InfiniteScroll>
      </div>

    </>

    
  )
}

export default LeaderBoardListing