import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Monthlyreport, MonthlyreportsQueryResponse} from './_models'
import {ID, Response} from '../../../../../_metronic/helpers'

// const API_URL = process.env.REACT_APP_THEME_API_URL
import {apiUrl} from '../../../../../apiurl'
const API_URL = apiUrl
const MONTHLYREPORT_URL = `${API_URL}/monthlyreport`
const GET_MONTHLYREPORTS_URL = `${API_URL}/users/query`
// ------
const GET_AVERAGE_FEEDBACK = `${API_URL}/monthlyleaderpoints/getAverageFeedback` //1
const GET_ATTENDANCE_GRAPH = `${API_URL}/monthlyleaderpoints/getAttendanceGraph` //2
const GET_INVITED_ATTENDED_JOINED_RATION = `${API_URL}/monthlyleaderpoints/getInvitedAttendedJoinedRatio` //3
const CHAPTER_NAME = `${API_URL}/chapters` //4
const GROUP_DROPDOWN = `${API_URL}/groups` //5
const USER_DETAIL = `${API_URL}/users` //6
const GET_TRACKSHEET_COUNT = `${API_URL}/monthlyleaderpoints/gettracksheetcount` //7
const TOPPERFORMERS_DATA = `${API_URL}/monthlyleaderpoints/getTopPerformers` //8
const MONTHLYLEADERPOINTS = `${API_URL}/monthlyleaderpoints` //9
const LEADERACTIVITYCUSTOMDATA = `${API_URL}/usertopics/getLeaderActivityCustomData` //10
const MONTHLYBI_REPORT = `${API_URL}/monthlybusinessleaderpoints/exportBIReport` //11
const LEADERGOALVSMRREPORT = `${API_URL}/managegoals/leaderGoalVsMRReport` //12
const EXPORT_GI_DATA = `${API_URL}/monthlyleaderpoints/exportGIdata` //13

const getMonthlyreports = (query: string): Promise<MonthlyreportsQueryResponse> => {
  return axios
    .get(`${GET_MONTHLYREPORTS_URL}?${query}`)
    .then((d: AxiosResponse<any>) => d.data)
    .catch((e) => console.log(e))
}

const getMonthlyreportById = (id: ID): Promise<Monthlyreport | undefined> => {
  return (
    axios
      .get(`${MONTHLYREPORT_URL}/${id}`)
      // .then((response: AxiosResponse<Response<Monthlyreport>>) => response.data).catch((e) => console.log(e))
      .then((response: Response<any>) => response.data)
      .catch((e) => console.log(e))
  )
}

const createMonthlyreport = (monthlyreport: Monthlyreport): Promise<Monthlyreport | undefined> => {
  return (
    axios
      .put(MONTHLYREPORT_URL, monthlyreport)
      // .then((response: AxiosResponse<Response<Monthlyreport>>) => response.data).catch((e) => console.log(e))
      .then((response: Response<any>) => response.data)
      .catch((e) => console.log(e))
  )
}

const updateMonthlyreport = (monthlyreport: Monthlyreport): Promise<Monthlyreport | undefined> => {
  return (
    axios
      .post(`${MONTHLYREPORT_URL}/${monthlyreport.id}`, monthlyreport)
      // .then((response: AxiosResponse<Response<Monthlyreport>>) => response.data).catch((e) => console.log(e))
      .then((response: Response<any>) => response.data)
      .catch((e) => console.log(e))
  )
}

const deleteMonthlyreport = (monthlyreportId: ID): Promise<void> => {
  return axios.delete(`${MONTHLYREPORT_URL}/${monthlyreportId}`).then(() => {})
}

const deleteSelectedMonthlyreports = (monthlyreportIds: Array<ID>): Promise<void> => {
  const requests = monthlyreportIds.map((id) => axios.delete(`${MONTHLYREPORT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
// --------------------------------
// 26- sep -23 irfan
const ExportGiData = (selectChapterId: any, selectYear: any, selectMonth: any) => {
  var query
  if (selectChapterId == 4) {
    query = `filter[where][month]=${selectMonth}&filter[where][year]=${selectYear}`
  } else {
    query = `filter[where][chapter_id]=${selectChapterId}&filter[where][month]=${selectMonth}&filter[where][year]=${selectYear}`
  }
  return axios
    .get(`${EXPORT_GI_DATA}?filter[where][leader_isdelete]=0&${query}`)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}
const GetAverageFeedback = (selectChapterId: any, selectYear: any, selectMonth: any) => {
  console.log(selectChapterId, 'haidar')
  var query
  if (selectChapterId == 4) {
    query = `filter[where][month][inq]=[${selectMonth}]&filter[where][year]=${selectYear}`
  } else {
    query = `filter[where][chapter_id]=${selectChapterId}&filter[where][month][inq]=[${selectMonth}]&filter[where][year]=${selectYear}`
  }
  return axios
    .get(
      `${GET_AVERAGE_FEEDBACK}?filter[where][leader_isactive]=1&filter[where][leader_isdelete]=0&${query}`
    )
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}
const GetAttendanceGraph = (selectChapterId: any, selectYear: any, selectMonth: any) => {
  var query
  if (selectChapterId == 4) {
    query = `filter[where][month]=[${selectMonth}]&filter[where][year]=${selectYear}`
  } else {
    query = `filter[where][chapter_id]=${selectChapterId}&filter[where][month]=[${selectMonth}]&filter[where][year]=${selectYear}`
  }
  return axios
    .get(
      `${GET_ATTENDANCE_GRAPH}?filter[where][leader_isactive]=1&filter[where][leader_isdelete]=0&${query}`
    )
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

const GetInvitedAttendedJoinedRatio = (selectChapterId: any, selectYear: any, selectMonth: any) => {
  var query
  if (selectChapterId == 4) {
    query = `filter[where][month][inq]=[${selectMonth}]&filter[where][year]=${selectYear}`
  } else {
    query = `filter[where][chapter_id]=${selectChapterId}&filter[where][month][inq]=[${selectMonth}]&filter[where][year]=${selectYear}`
  }
  return axios
    .get(
      `${GET_INVITED_ATTENDED_JOINED_RATION}?filter[where][leader_isactive]=1&filter[where][leader_isdelete]=0&${query}`
    )
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}
const ChapterName = () => {
  return axios
    .get(
      `${CHAPTER_NAME}?filter[where][isdelete]=0&filter[where][id][inq]=3&filter[where][id][inq]=6`
    )
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}
const GroupsDropDown = (): any => {
  return axios
    .get(
      `${GROUP_DROPDOWN}?filter[where][isdelete]=0&filter[where][chapter_id][inq]=3&filter[where][chapter_id][inq]=6`
    )
    .then((responce: any) => {
      return responce?.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

const UserDetail = (): any => {
  return axios
    .get(
      `${USER_DETAIL}?filter[where][isdelete]=0&filter[where][isactive]=1&filter[order]=name`
    )
    .then((responce: any) => {
      return responce?.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
} //
const GetTrackSheetCount = (selectChapterId: any, selectYear: any, selectMonth: any): any => {
  var query
  if (selectChapterId == 4) {
    query = `filter[where][month][inq]=[${selectMonth}]&filter[where][year]=${selectYear}`
  } else {
    query = `filter[where][chapter_id]=${selectChapterId}&filter[where][month][inq]=[${selectMonth}]&filter[where][year]=${selectYear}`
  }
  return axios
    .get(
      `${GET_TRACKSHEET_COUNT}?filter[where][leader_isactive]=1&filter[where][leader_isdelete]=0&${query}`
    )
    .then((responce: any) => {
      return responce?.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

const TopPerformersData = (selectChapterId: any, selectYear: any, selectMonth: any): any => {
  var query
  if (selectChapterId == 4) {
    query = `filter[where][month]=[${selectMonth}]&filter[where][year]=${selectYear}`
  } else {
    query = `filter[where][chapter_id]=${selectChapterId}&filter[where][month]=[${selectMonth}]&filter[where][year]=${selectYear}`
  }
  return axios
    .get(
      `${TOPPERFORMERS_DATA}?filter[where][leader_isactive]=1&filter[where][leader_isdelete]=0&${query}`
    )
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}
//---- 25-sep-23 irfan
const LeaderActivityCustomData = (startDate: any, endDate: any): any => {
  return axios
    .get(`${LEADERACTIVITYCUSTOMDATA}?startdate=${startDate}&enddate=${endDate}`)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

const MonthlyBiReport = (startDate: any, endDate: any): any => {
  return axios
    .get(`${MONTHLYBI_REPORT}?startdate=${startDate}&enddate=${endDate}`)
    .then((responce: any) => {
      return responce
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}
const LeaderGoalVsMrReport = (startDate: any, endDate: any): any => {
  return axios
    .get(`${LEADERGOALVSMRREPORT}?startdate=${startDate}&enddate=${endDate}`)
    .then((responce: any) => {
      return responce.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}
const MonthlyReportTableData = (query: string): any => {
  function extractFilterChapterId(query: any) {
    const queryString = decodeURI(query)
    const params = new URLSearchParams(queryString)
    const filterChapterId = params.get('filter_chapter_id')

    return filterChapterId
  }
 
  const filterChapterId = extractFilterChapterId(query)

  return axios
    .get(
      `${MONTHLYLEADERPOINTS}?isWeb=true&filter[include]=user&filter[where][leader_isactive]=1&filter[where][chapter_id]=6&filter[where][leader_isdelete]=0&filter[where][month][inq]=10&filter[where][month][inq]=11&filter[where][month][inq]=9&filter[where][year]=2023&draw=31&${query}`
    )
    .then((responce: any) => {
      return responce?.data
    })
    .catch((error) => {
      console.error('Error All Post Data:', error)
      throw error
    })
}

export {
  getMonthlyreports,
  deleteMonthlyreport,
  deleteSelectedMonthlyreports,
  getMonthlyreportById,
  createMonthlyreport,
  updateMonthlyreport,
  LeaderActivityCustomData,
  LeaderGoalVsMrReport,
  MonthlyBiReport,
  TopPerformersData,
  GroupsDropDown,
  MonthlyReportTableData,
  GetTrackSheetCount,
  UserDetail,
  ChapterName,
  GetInvitedAttendedJoinedRatio,
  GetAttendanceGraph,
  GetAverageFeedback,
  ExportGiData,
}
