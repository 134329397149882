import React, {useRef} from 'react'
import {useMemo, useState, useEffect, useContext, useCallback} from 'react'
import {Button, Form, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useDropzone} from 'react-dropzone'
import Select from 'react-select'
import {useQueryRequest} from './events-list/core/QueryRequestProvider'
import {
  getChaptersList,
  addEvent,
  getEventData,
  viewEventData,
  editEvent,
} from './events-list/core/_requests'
// import { useFormik, Formik } from 'formik'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {UserContext} from './../../../contextStore'
import {EventsTable} from '../event-management/events-list/table/EventsTable'
import {useQueryResponse} from './events-list/core/QueryResponseProvider'
import axios from 'axios'
import {apiUrl} from '../../../apiurl'
import {useNavigate, useParams} from 'react-router-dom'
import moment from 'moment'
// import { useFormik } from 'formik'
// import {addEvent} from '../core/_requests'
// import { getChaptersList } from './events-list'
const API_URL = apiUrl
const IMAGE_UPLOAD = `${API_URL}/containers/topicimages/upload`

// const AddEditEventForm = ({ closebutton }: any, { invokedFrom }: any) => {

const AddEditEventForm = ({id, invokedFrom}: any) => {
  const {refetch} = useQueryResponse()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const {setAuth, setMessage, setChapterID, refreshEventTable} = useContext(UserContext)
  const [refreshTable, setRefresh] = useState<any>(false)
  const [loadingGetData, setLoadingGetData] = useState(false)
  const [dateToShow, setDate] = useState('')
  const [imageError, setImageError] = useState(false)
  const closebutton = useRef<any>()

  const initialValues = {
    chapter: '',
    event_name: '',
    event_date: '',
    start_time: '',
    end_time: '',
    google_map_link: '',
    registration_link: '',
    breakfast_fees: '',
    registration_fees: '',
    zoom_id: '',
    zoom_password: '',
    venue: '',
    schedule_description: '',
    address: '',
    hotel_logo: '',
    email_inviation_image: '',
    sms_inviation_image: '',
  }

  const addEventSchema = Yup.object().shape({
    chapter: Yup.string().required('Chapter is required'),
    event_date: Yup.string().required('Event Date is required'),
    start_time: Yup.string().required('Start Time is required'),
    end_time: Yup.string()
      .required('End Time is required')
      .test('is-greater', 'End time must be greater than start time', function (value) {
        const {start_time} = this.parent
        if (start_time && value) {
          const startTimeMoment = moment(start_time, 'HH:mm')
          const endTimeMoment = moment(value, 'HH:mm')
          return endTimeMoment.isAfter(startTimeMoment)
        }
        return true
      }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: addEventSchema,
    onSubmit: async (values, {resetForm}) => {
      try {
        setLoading(true)
        const Data = {
          chapter: values.chapter,
          event_name: values.event_name,
          event_date: values.event_date,
          start_time: values.start_time,
          end_time: values.end_time,
          google_map_link: values.google_map_link,
          registration_link: values.registration_link,
          breakfast_fees: values.breakfast_fees,
          registration_fees: values.registration_fees ,
          zoom_id: values.zoom_id,
          zoom_password: values.zoom_password,
          venue: values.venue,
          schedule_description: values.schedule_description,
          address: values.address,
          hotel_logo: hotelLogoName != null ? hotelLogoName : formik.values.hotel_logo,
          email_inviation_image: emailInvite != null ? emailInvite : formik.values.email_inviation_image,
          sms_inviation_image: smsInvite != null ? smsInvite : formik.values.sms_inviation_image,
          id: id,
        }

        if (invokedFrom == 'AddEvent') {

          await addEvent(Data)
          setMessage('success', 'Event Added Successfully.')
          refetch()
          // fetchData()
          closebutton.current.click()
          setSelectedHotelLogo(null)
          setHotelLogoName('')
          setSelectedEmailInvite(null)
          setEmailInvite('')
          setSelectedSMSInvite(null)
          setSMSInvite('')
          resetForm()
          setLoading(false)
        }

        if (invokedFrom == 'EditEvent') {

          await editEvent(Data)
          setMessage('success', 'Event Edited Successfully.')
          refetch()
          // fetchData()
          closebutton.current.click()
          setSelectedHotelLogo(null)
          setHotelLogoName('')
          setSelectedEmailInvite(null)
          setEmailInvite('')
          setSelectedSMSInvite(null)
          setSMSInvite('')
          // resetForm()
          setLoading(false)
        }
      } catch (error:any) {
        setMessage('error', error?.message ?? 'Something went wrong')
        console.log('erorr = ', error)
        setLoading(false)
      }
    },
  }) as any

  const getByID = async (id: string) => {
    try {
      setLoadingGetData(true)
      let data: any = await viewEventData(id)

      let fetchedEventData = data?.data[0] ?? []

      let date = new Date(fetchedEventData.event_date)

      const day = date.getDate()
      const month = date.getMonth() + 1 // Months are zero-based, so add 1
      const year = date.getFullYear()

      // Format the day, month, and year as "dd mm yyyy"
      // const formattedDate = `${day}-${month}-${year}`;
      const formattedDate = `${year}-${month}-${day}`

      setDate(formattedDate)

      if (fetchedEventData != null) {
        // if ('chapter_id' in fetchedEventData) {
        //     formik.setFieldValue('chapter_id', fetchedEventData.chapter_id)
        // }
        if ('chapter_id' in fetchedEventData) {
          formik.setFieldValue('chapter', fetchedEventData.chapter_id)
          // formik.setFieldValue('chapter', "Temp")
        }
        if ('event_name' in fetchedEventData) {
          formik.setFieldValue('event_name', fetchedEventData.event_name)
        }
        if ('event_date' in fetchedEventData) {
          // formik.setFieldValue('event_date', fetchedEventData.event_date)

          let date = new Date(fetchedEventData.event_date)

          const day = date.getDate()
          const month = date.getMonth() + 1 // Months are zero-based, so add 1
          const year = date.getFullYear()

          const tempDate = `${year}-${month}-${day}`

          // const inputDate = "2000-10-4";
          const parts = tempDate.split('-')
          const Year = parts[0]
          const Month = parts[1]
          const Day = parts[2]

          // Ensure the month and day have leading zeros if they are single digits
          const formattedDate = `${Year}-${Month.padStart(2, '0')}-${Day.padStart(2, '0')}`

          formik.setFieldValue('event_date', formattedDate)
        }
        if ('start_time' in fetchedEventData) {
          formik.setFieldValue('start_time', fetchedEventData.start_time)
        }
        if ('end_time' in fetchedEventData) {
          formik.setFieldValue('end_time', fetchedEventData.end_time)
        }
        if ('google_map_link' in fetchedEventData) {
          formik.setFieldValue('google_map_link', fetchedEventData.google_map_link)
        }
        if ('registration_link' in fetchedEventData) {
          formik.setFieldValue('registration_link', fetchedEventData.registration_link)
        }
        if ('breakfast_fees' in fetchedEventData) {
          formik.setFieldValue('breakfast_fees', fetchedEventData.breakfast_fees)
        }
        if ('registration_fees' in fetchedEventData) {
          formik.setFieldValue('registration_fees', fetchedEventData.registration_fees)
        }
        if ('zoom_id' in fetchedEventData) {
          formik.setFieldValue('zoom_id', fetchedEventData.zoom_id)
        }
        if ('zoom_password' in fetchedEventData) {
          formik.setFieldValue('zoom_password', fetchedEventData.zoom_password)
        }
        if ('venue' in fetchedEventData) {
          formik.setFieldValue('venue', fetchedEventData.venue)
        }
        if ('schedule_description' in fetchedEventData) {
          formik.setFieldValue('schedule_description', fetchedEventData.schedule_description)
        }
        if ('address' in fetchedEventData) {
          formik.setFieldValue('address', fetchedEventData.address)
        }
        if ('hotel_logo' in fetchedEventData) {
          formik.setFieldValue('hotel_logo', fetchedEventData.hotel_logo)
          setHotelLogoName(fetchedEventData.hotel_logo)
          // formik.setFieldValue(selectedHotelLogo, fetchedEventData.hotel_logo)
          // selectedHotelLogo = fetchedEventData.hotel_logo
          // setSelectedHotelLogo(fetchedEventData.hotel_logo)
        }
        if ('email_inviation_image' in fetchedEventData) {
          formik.setFieldValue('email_inviation_image', fetchedEventData.email_inviation_image)
          setEmailInvite(fetchedEventData.email_inviation_image)
        }
        if ('sms_inviation_image' in fetchedEventData) {
          formik.setFieldValue('sms_inviation_image', fetchedEventData.sms_inviation_image)
          setSMSInvite(fetchedEventData.sms_inviation_image)
        }
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  useEffect(() => {
    if (id) {
      getByID(id)
    }
  }, [])

  useEffect(() => {
    formik.validateForm()
  }, [])

  const [selectedHotelLogo, setSelectedHotelLogo] = useState<File | null>(null)
  const [hotelLogoName, setHotelLogoName] = useState<String | null>('')

  const [selectedEmailInvite, setSelectedEmailInvite] = useState<File | null>(null)
  const [emailInvite, setEmailInvite] = useState<String | null>('')

  const [selectedSMSInvite, setSelectedSMSInvite] = useState<File | null>(null)
  const [smsInvite, setSMSInvite] = useState<String | null>('')

  const onDropHotel = useCallback((acceptedFiles: any) => {
    const file = acceptedFiles[0]
    setSelectedHotelLogo(file)
  }, [])

  const {getRootProps: getHotelRootProps, getInputProps: getHotelInputProps} = useDropzone({
    onDrop: onDropHotel,
    accept: 'pdf/*' as any,
    maxFiles: 1,
  })

  const onDropEmail = useCallback((acceptedFiles: any) => {
    const file = acceptedFiles[0]
    setSelectedEmailInvite(file)
  }, [])

  const {getRootProps: getEmailRootProps, getInputProps: getEmailInputProps} = useDropzone({
    onDrop: onDropEmail,
    accept: 'pdf/*' as any,
    maxFiles: 1,
  })

  const onDropSMS = useCallback((acceptedFiles: any) => {
    const file = acceptedFiles[0]
    setSelectedSMSInvite(file)
  }, [])

  const {getRootProps: getSMSRootProps, getInputProps: getSMSInputProps} = useDropzone({
    onDrop: onDropSMS,
    accept: 'pdf/*' as any,
    maxFiles: 1,
  })

  useEffect(() => {
    if (selectedHotelLogo) {
      try {
        uploadHotelImage()
      } catch (e) {
        setMessage('error', 'Failed to upload image.')
      }
    }
  }, [selectedHotelLogo])

  useEffect(() => {
    if (selectedEmailInvite) {
      try {
        uploadEmailImage()
      } catch (e) {
        setMessage('error', 'Failed to upload image.')
      }
    }
  }, [selectedEmailInvite])

  useEffect(() => {
    if (selectedSMSInvite) {
      try {
        uploadSMSImage()
      } catch (e) {
        setMessage('error', 'Failed to upload image.')
      }
    }
  }, [selectedSMSInvite])

  const uploadHotelImage = async () => {
    
    try {
      if (selectedHotelLogo) {
        const formData = new FormData()
        formData.append('file', selectedHotelLogo)

        const response = await axios.post(IMAGE_UPLOAD, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': `attachment; filename="${selectedHotelLogo.name}"`,
          },
        })

        if (response.status === 200) {
          setHotelLogoName(response?.data?.result?.files?.file[0]?.name)
          //console.log('Image uploaded successfully3:', response?.data?.result?.files?.file[0]?.name)
        } else {
          //console.error('Image upload failed:', response.statusText)
        }
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const uploadEmailImage = async () => {
    try {
      if (selectedEmailInvite) {
        const formData = new FormData()
        formData.append('file', selectedEmailInvite)

        const response = await axios.post(IMAGE_UPLOAD, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': `attachment; filename="${selectedEmailInvite.name}"`,
          },
        })

        if (response.status === 200) {
          setEmailInvite(response?.data?.result?.files?.file[0]?.name)
          //console.log('Image uploaded successfully3:', response?.data?.result?.files?.file[0]?.name)
        } else {
          console.error('Image upload failed:', response.statusText)
        }
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const uploadSMSImage = async () => {
    try {
      if (selectedSMSInvite) {
        const formData = new FormData()
        formData.append('file', selectedSMSInvite)

        const response = await axios.post(IMAGE_UPLOAD, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': `attachment; filename="${selectedSMSInvite.name}"`,
          },
        })

        if (response.status === 200) {
          setSMSInvite(response?.data?.result?.files?.file[0]?.name)
          console.log('Image uploaded successfully3:', response?.data?.result?.files?.file[0]?.name)
        } else {
          console.error('Image upload failed:', response.statusText)
        }
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const [chapterList, setChapterList] = useState<any>([
    {label:"Select Chapter",value:""}
    // {value: ''},
  ])

  const {state, updateState} = useQueryRequest() as any

  const getChapter = async () => {
    await getChaptersList()
      .then((response: any) => {
        if (response && response?.length > 0) {
          let clist =
            (response?.map((x: any) => {
              return {label: x?.name, value: x?.id}
            }) as any) ?? []
          // clist.unshift({label:"Select Chapter",value:""})
          // clist.unshift({value: ''})
          setChapterList(clist)
        }
        // response
      })
      .catch((error: any) => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    getChapter()
  }, [])

  const [eventData, setEventData] = useState<any>([
    // {label:"All",value:""}
    {value: ''},
  ])

  const getEvent = async () => {

    await getEventData()
      .then((response: any) => {
        if (response && response?.length > 0) {
          let clist =
            (response?.map((x: any) => {
              return {label: x?.name, value: x?.id}
            }) as any) ?? []
          clist.unshift({label: 'All', value: ''})
          setEventData(clist)
          // refreshEventTable()
        }
        // response
      })
      .catch((error: any) => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    getEvent()
  }, [])

  const handleImageError = () => {
    setImageError(true)
  }

  const resetForm = () => {
    if(!id){
      formik.resetForm()
      setSelectedHotelLogo(null)
      setSelectedEmailInvite(null)
      setSelectedHotelLogo(null)
    }
  }

  return (
    <>
      <div className='modal-header border-bottom border-gray-300'>
        <h5 className='modal-title text-primary' id='staticBackdropLabel'>
          {id ? 'Update ' : 'Add '}Event
        </h5>
        <button
          type='button'
          className='btn-close'
          data-bs-dismiss='modal'
          aria-label='Close'
          ref={closebutton}
          onClick={() => {
            resetForm()
          }}
        ></button>
      </div>
      {/* <Form onSubmit={formik.handleSubmit}> */}
      <form onSubmit={formik.handleSubmit}>
        <div className='modal-body'>
          <div className='col-md-12 mb-8'>
            <Form.Label className='fs-7 text-primary w-100  text-start'>
              {' '}
              Select Chapter
              <span className='text-danger'> * </span>{' '}
            </Form.Label>
            <Form.Select
              aria-label='Default select example'
              // defaultValue = {formik.values.chapter}
              onChange={(e: any) => {
                formik.setFieldValue('chapter', e.target.value)
              }}
              value={formik.values.chapter}
            >
              <option value="">Select chapter</option>
              {chapterList.map((x: any) => {
                return <option value={x?.value}>{x?.label}</option>
              })}
            </Form.Select>
            {(formik.touched.chapter && formik.errors.chapter) && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'><strong>{formik.errors.chapter}</strong></div>
              </div>
            )}
            
          </div>

          <div className='col-12 mb-8'>
            <Form.Label className='fs-7 text-primary w-100  text-start'>
              {' '}
              Event Name
              {' '}
            </Form.Label>
            <Form.Control
              type='text'
              // placeholder=""
              value={formik.values.event_name}
              onChange={(e) => {
                formik.setFieldValue('event_name', e.target.value)
              }}
            />
          </div>

          <div className='row mt-8'>
            <div className='col-md-12 col-lg-4'>
              <Form.Label className='fs-7 text-primary w-100  text-start'>
                {' '}
                Event Date <span className='text-danger'> * </span>{' '}
              </Form.Label>
              <Form.Control
                type='date'
                // placeholder="Presentation  Title"
                value={formik.values.event_date}
                onChange={(e) => {
                  formik.setFieldValue('event_date', e.target.value)
                }}
              />
              {(formik.touched.event_date && formik.errors.event_date) && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'><strong>{formik.errors.event_date}</strong></div>
                </div>
              )}
            </div>
            <div className='col-md-12 col-lg-4'>
              <Form.Label className='fs-7 text-primary w-100  text-start'>
                {' '}
                Start Time <span className='text-danger'> * </span>{' '}
              </Form.Label>
              <Form.Control
                type='time'
                // placeholder="Presentation  Title"
                value={formik.values.start_time}
                onChange={(e) => {
                  formik.setFieldValue('start_time', e.target.value)
                }}
              />
              {(formik.touched.start_time && formik.errors.start_time) && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'><strong>{formik.errors.start_time}</strong></div>
                </div>
              )}
            </div>
            <div className='col-md-12 col-lg-4'>
              <Form.Label className='fs-7 text-primary w-100  text-start'>
                {' '}
                End Time <span className='text-danger'> * </span>{' '}
              </Form.Label>
              <Form.Control
                type='time'
                // placeholder="Presentation  Title"
                value={formik.values.end_time}
                onChange={(e) => {
                  formik.setFieldValue('end_time', e.target.value)
                }}
              />
              {(formik.touched.end_time && formik.errors.end_time) && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'><strong>{formik.errors.end_time}</strong></div>
                </div>
              )}
            </div>
          </div>

          <div className='row mt-8'>
            <div className='col-md-12 col-lg-6'>
              <Form.Label className='fs-7 text-primary w-100  text-start'>
                {' '}
                Google Map Link{' '}
              </Form.Label>
              <Form.Control
                type='text'
                // placeholder=""
                value={formik.values.google_map_link}
                onChange={(e) => {
                  formik.setFieldValue('google_map_link', e.target.value)
                }}
              />
            </div>
            <div className='col-md-12 col-lg-6'>
              <Form.Label className='fs-7 text-primary w-100  text-start'>
                {' '}
                Registration Link{' '}
              </Form.Label>
              <Form.Control
                type='text'
                // placeholder=""
                value={formik.values.registration_link}
                onChange={(e) => {
                  formik.setFieldValue('registration_link', e.target.value)
                }}
              />
            </div>
          </div>

          <div className='row mt-8'>
            <div className='col-md-12 col-lg-6'>
              <Form.Label className='fs-7 text-primary w-100  text-start'>
                {' '}
                Breakfast Fees{' '}
              </Form.Label>
              <Form.Control
                type='text'
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  const key = e.key
                  const isNumeric = /^[0-9]$/.test(key)
                  const isSpecialKey =
                    key === 'Backspace' ||
                    key === 'Delete' ||
                    key === 'ArrowLeft' ||
                    key === 'ArrowRight' ||
                    key === 'ArrowUp' ||
                    key === 'ArrowDown'
                  if (!isNumeric && !isSpecialKey) {
                    e.preventDefault()
                  }
                }}
                // placeholder=""
                value={formik.values.breakfast_fees}
                onChange={(e) => {
                  formik.setFieldValue('breakfast_fees', e.target.value)
                }}
              />
            </div>
            <div className='col-md-12 col-lg-6'>
              <Form.Label className='fs-7 text-primary w-100  text-start'>
                {' '}
                Registration Fees{' '}
              </Form.Label>
              <Form.Control
                type='text'
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  const key = e.key
                  const isNumeric = /^[0-9]$/.test(key)
                  const isSpecialKey =
                    key === 'Backspace' ||
                    key === 'Delete' ||
                    key === 'ArrowLeft' ||
                    key === 'ArrowRight' ||
                    key === 'ArrowUp' ||
                    key === 'ArrowDown'
                  if (!isNumeric && !isSpecialKey) {
                    e.preventDefault()
                  }
                }}
                // placeholder=""
                value={formik.values.registration_fees}
                onChange={(e) => {
                  formik.setFieldValue('registration_fees', e.target.value)
                }}
              />
            </div>
          </div>

          <div className='row mt-5'>
            <div className='col-md-12 col-lg-6'>
              <Form.Label className='fs-7 text-primary w-100  text-start'>
                {' '}
                Zoom ID{' '}
              </Form.Label>
              <Form.Control
                type='text'
                // placeholder=""
                value={formik.values.zoom_id}
                onChange={(e) => {
                  formik.setFieldValue('zoom_id', e.target.value)
                }}
              />
            </div>
            <div className='col-md-12 col-lg-6'>
              <Form.Label className='fs-7 text-primary w-100  text-start'>
                {' '}
                Zoom Password{' '}
              </Form.Label>
              <Form.Control
                type='password'
                // placeholder=""
                value={formik.values.zoom_password}
                onChange={(e) => {
                  formik.setFieldValue('zoom_password', e.target.value)
                }}
              />
            </div>
          </div>

          <div className='row mt-5'>
            <div className='col-md-12 col-lg-6'>
              <Form.Label className='fs-7 text-primary w-100  text-start'>
                {' '}
                Venue{' '}
              </Form.Label>
              <Form.Control
                type='text'
                // placeholder=""
                value={formik.values.venue}
                onChange={(e) => {
                  formik.setFieldValue('venue', e.target.value)
                }}
              />
            </div>
          </div>

          <div className='col-md-12'>
            <Form.Label className='fs-7 text-primary w-100  text-start'>
              {' '}
              Schedule Description{' '}
            </Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              placeholder=''
              value={formik.values.schedule_description}
              onChange={(e) => {
                formik.setFieldValue('schedule_description', e.target.value)
              }}
            />
          </div>

          <div className='col-md-12'>
            <Form.Label className='fs-7 text-primary w-100  text-start'>
              {' '}
              Address{' '}
            </Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              placeholder=''
              value={formik.values.address}
              onChange={(e) => {
                formik.setFieldValue('address', e.target.value)
              }}
            />
          </div>

          <div className='col-12 mt-10'>
            <section className='container'>
              <h4 className='fw-normal fs-6 border-gray-600'> Hotel Logo</h4>
              <div {...getHotelRootProps({className: 'dropzone'})}>
                <input {...getHotelInputProps()} />
                {selectedHotelLogo ? (
                  <p>Selected File: {selectedHotelLogo?.name}</p>
                ) : (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
              </div>

              {selectedHotelLogo ? (
                <div>
                  {imageError && !selectedHotelLogo ? (
                    <p>Image not found</p>
                  ) : (
                    <img
                      width={100}
                      src={URL.createObjectURL(selectedHotelLogo)}
                      onError={handleImageError}
                      // alt='Selected'
                      style={{maxWidth: '100%', maxHeight: '200px'}}
                    />
                  )}
                </div>
              ) : invokedFrom == 'EditEvent' && formik.values.hotel_logo ? (
                <div>
                  {imageError && !selectedHotelLogo ? (
                    <p>Image not found</p>
                  ) : (
                    <img
                      width={100}
                      src={`${API_URL}/containers/topicimages/download/${formik.values.hotel_logo}`}
                      onError={handleImageError}
                      // alt='Selected'
                      style={{maxWidth: '100%', maxHeight: '200px'}}
                    />
                  )}
                </div>
              ) : (
                'No file selected'
              )}
            </section>
          </div>

          <div className='col-12 mt-10'>
            <section className='container'>
              <h4 className='fw-normal fs-6 border-gray-600'> Email Invitation Image</h4>
              <div {...getEmailRootProps({className: 'dropzone'})}>
                <input {...getEmailInputProps()} />
                {selectedEmailInvite ? (
                  <p>Selected File: {selectedEmailInvite?.name}</p>
                ) : (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
              </div>

              {selectedEmailInvite ? (
                <div>
                  {imageError && !selectedEmailInvite ? (
                    <p>Image not found</p>
                  ) : (
                    <img
                      width={100}
                      src={URL.createObjectURL(selectedEmailInvite)}
                      onError={handleImageError}
                      // alt='Selected'
                      style={{maxWidth: '100%', maxHeight: '200px'}}
                    />
                  )}
                </div>
              ) : invokedFrom == 'EditEvent' && formik.values.email_inviation_image ? (
                <div>
                  {imageError && !selectedEmailInvite ? (
                    <p>Image not found</p>
                  ) : (
                    <img
                      width={100}
                      src={`${API_URL}/containers/topicimages/download/${formik.values.email_inviation_image}`}
                      onError={handleImageError}
                      // alt='Selected'
                      style={{maxWidth: '100%', maxHeight: '200px'}}
                    />
                  )}
                </div>
              ) : (
                'No file selected'
              )}
            </section>
          </div>

          <div className='col-12 mt-10'>
            <section className='container'>
              <h4 className='fw-normal fs-6 border-gray-600'> SMS Invitation Image</h4>
              <div {...getSMSRootProps({className: 'dropzone'})}>
                <input {...getSMSInputProps()} />
                {selectedSMSInvite ? (
                  <p>Selected File: {selectedSMSInvite?.name}</p>
                ) : (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
              </div>

              {selectedSMSInvite ? (
                <div>
                  {imageError && !selectedSMSInvite ? (
                    <p>Image not found</p>
                  ) : (
                    <img
                      width={100}
                      src={URL.createObjectURL(selectedSMSInvite)}
                      onError={handleImageError}
                      // alt='Selected'
                      style={{maxWidth: '100%', maxHeight: '200px'}}
                    />
                  )}
                </div>
              ) : invokedFrom == 'EditEvent' && formik.values.sms_inviation_image ? (
                <div>
                  {imageError && !selectedSMSInvite ? (
                    <p>Image not found</p>
                  ) : (
                    <img
                      width={100}
                      src={`${API_URL}/containers/topicimages/download/${formik.values.sms_inviation_image}`}
                      onError={handleImageError}
                      // alt='Selected'
                      style={{maxWidth: '100%', maxHeight: '200px'}}
                    />
                  )}
                </div>
              ) : (
                'No file selected'
              )}
            </section>
          </div>

          <div className='modal-footer position-absolute bottom-0 w-100 pe-5'>
            <Button
              variant='primary'
              type='submit'
              className='w-100 me-12'
              style={{backgroundColor: '#f5f5f5'}}
              disabled={loading}
            >
              {loading ? "Please Wait..." : "Submit"}
            </Button>
          </div>
        </div>
      </form>
    </>
  )
}

export default AddEditEventForm
